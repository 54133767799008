import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid2,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";
import { letterheadOptions } from "../../config/loginDetails";
import { loiTemplates } from "../../templates/utils";
import { IndemnityModal } from "../../components/generation/IndemnityModal";
import PropTypes from 'prop-types';
import { UserContext } from '../../context/UserContext';

const LOI2LOIResult = ({ response, requestorName, requestorCompany, requestorDesignation, owners, address }) => {
    LOI2LOIResult.propTypes = {
        response: PropTypes.object.isRequired,
        requestorName: PropTypes.string,
        requestorCompany: PropTypes.string,
        requestorDesignation: PropTypes.string,
        owners: PropTypes.string,
        address: PropTypes.string,
    };
    const { user } = useContext(UserContext);
    // Filter letterhead options based on user organization
    const availableLetterheadOptions = letterheadOptions.filter(option => option.organisation === user.orgId);
    const [LOIResult, setLOIResult] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLetterhead, setSelectedLetterhead] = useState(null);
    const [selectedLoiTemplate, setSelectedLoiTemplate] = useState(loiTemplates[0]);

    useEffect(() => {
        setLOIResult(response);

        // Only set the default letterhead on the initial load if selectedLetterhead is not set
        if (!selectedLetterhead) {
            const matchingLetterhead = availableLetterheadOptions.find(option => option.label === requestorCompany);
            setSelectedLetterhead(matchingLetterhead ? matchingLetterhead.path : availableLetterheadOptions[0]?.path);
        }
    }, [response, user.orgId, requestorCompany, availableLetterheadOptions, selectedLetterhead]);

    useEffect(() => {
        if (isModalOpen) {
            console.log("Selected letterhead updated:", selectedLetterhead);
        }
    }, [selectedLetterhead, selectedLoiTemplate, isModalOpen]);

    if (!LOIResult) return null;

    const { loi2loi_details, total_time, pdf_storage_time, loi2loi_extraction_time, request_id } = LOIResult;

    return (
        <Box sx={{ padding: 2 }}>
            <Card>
                <CardContent>
                    <Typography variant="h5">LOI to LOI Generation Results</Typography>
                    <Button
                        variant="text"
                        fullWidth
                        onClick={() => setExpanded(!expanded)}
                        sx={{ mt: 3 }}
                        style={{ justifyContent: "flex-start" }}
                    >
                        {`Response time: ${total_time.toFixed(2)} seconds`}
                    </Button>
                    <Collapse in={expanded}>
                        <Grid2 container spacing={2}>
                            <Grid2 item size={{ xs: 12, sm: 6 }}>
                                <Typography sx={{ mb: 1 }}>Total Time</Typography>
                                <Typography sx={{ mb: 2 }} variant="h4">
                                    {total_time.toFixed(2)} seconds
                                </Typography>
                                <Typography sx={{ mb: 1 }}>PDF Storage Time</Typography>
                                <Typography variant="h4">
                                    {pdf_storage_time.toFixed(2)} seconds
                                </Typography>
                            </Grid2>
                            <Grid2 item size={{ xs: 12, sm: 6 }}>
                                <Typography sx={{ mb: 1 }}>LOI Extraction Time</Typography>
                                <Typography sx={{ mb: 2 }} variant="h4">
                                    {loi2loi_extraction_time.toFixed(2)} seconds
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Collapse>

                    {/* Letterhead Image Selector */}
                    <Box sx={{ mt: 3 }}>
                        <Typography>Choose Letterhead</Typography>
                        <Select
                            value={selectedLetterhead}
                            onChange={(e) => setSelectedLetterhead(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 1 }}
                        >
                            {availableLetterheadOptions.map((option) => (
                                <MenuItem key={option.path} value={option.path}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={option.path}
                                            alt={option.label}
                                            style={{ width: 200, height: 50, marginRight: 8 }}
                                        />
                                        {option.label}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography>Choose Loi Template</Typography>
                        <Select value={selectedLoiTemplate}
                            onChange={(e) => setSelectedLoiTemplate(e.target.value)}
                            fullWidth
                            variant="outlined"
                            renderValue={(value) => value.label}
                        >
                            {loiTemplates.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsModalOpen(true)}
                        style={{ marginTop: "16px" }}
                    >
                        See Letter of Indemnity
                    </Button>
                    <IndemnityModal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        currentDate={new Date().toLocaleDateString()}
                        requestorName={requestorName}
                        requestorCompany={requestorCompany}
                        requestorDesignation={requestorDesignation}
                        details={loi2loi_details}
                        owners={owners}
                        letterheadImage={selectedLetterhead}
                        loiTemplate={selectedLoiTemplate}
                        requestId={request_id}
                        address={address}
                    />
                </CardContent>
            </Card>
        </Box>
    );
};

export default LOI2LOIResult;
