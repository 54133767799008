
import axios from 'axios';
import { API_URL } from "./config";

// Function to compare hire statements
export const compareHireStatements = async (hireStatement1, hireStatement2, ocr, vision, useContentCache, organisationId) => {
    const formData = new FormData();

    formData.append('hire_statement_1', hireStatement1);
    formData.append('hire_statement_2', hireStatement2);
    formData.append('ocr', ocr);
    formData.append('vision', vision);
    formData.append('use_cache', useContentCache);
    formData.append("organisation_id", organisationId);

    try {
        const response = await axios.post(`${API_URL}/hire_comparison/v2/compare_hire_statements/files`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data;
    } catch (error) {
        console.error("Error comparing hire statements:", error);
        throw error;
    }
};
