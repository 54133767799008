import React, { useState, useContext, useEffect } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    Box,
    Collapse,
    Grid2,
    CircularProgress,
    Divider,
    Alert,
    Select,
    MenuItem,
} from "@mui/material";
import { loi2loi } from "../../services/loi2loiService";
import { getNumberOfPages, splicePdf } from "../../helpers"
import LOI2LOIResult from "./LOI2LOIResult";
import Feedback from "../../components/common/Feedback";
import DocumentUploader from '../../components/common/DocumentUploader'
import ToolHeader from "../../components/common/ToolHeader"
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar"
import { UserContext } from '../../context/UserContext';
import { requesterCompanyOptions } from "../../config/loginDetails";

const LOI2LOI = () => {
    const { user } = useContext(UserContext);
    // Filter letterhead options based on user organization
    const availableRequesterOptions = requesterCompanyOptions.filter(option => option.organisation === user.orgId);

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [useOCR, setUseOCR] = useState(true);
    const [useVision, setUseVision] = useState(true);
    const [useContentCache, setUseContentCache] = useState(true);
    const [useLOICache, setUseLOICache] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const [requesterCompany, setRequesterCompany] = useState(availableRequesterOptions[0]?.label);
    const [requestorAddress] = useState(availableRequesterOptions[0]?.address);
    const [requesterName, setRequesterName] = useState(user.fullName);
    const [requesterDesignation, setRequesterDesignation] = useState("");
    const [owners, setOwners] = useState("ABC Shipping Co., Ltd.");
    const [pageCount, setPageCount] = useState(1);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(pageCount ? pageCount : 1);
    const steps = ["Upload", "Generate LOI"];
    const [currentStep, setCurrentStep] = useState(0);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    useEffect(() => {
        if (uploadedFile.length > 0) {
            getNumberOfPages(uploadedFile[0]).then((pageCount) => { setPageCount(pageCount); setEndPage(pageCount) });
        }
    }, [uploadedFile]);

    const handleAdvancedOptionsClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const handleGeneration = async () => {
        if (uploadedFile.length === 0) {
            alert("Please upload a file before generating");
            return;
        }
        console.log("Generating LOI from bill of lading...", {
            uploadedFile,
            requester: {
                requesterCompany,
                requesterName,
                requesterDesignation,
            },
            options: {
                useOCR,
                useVision,
                useContentCache,
                useLOICache,
            },
        });

        setLoading(true);
        setError(null); // Clear any previous errors
        setResult(null); // Clear previous result

        try {
            const splicedFile = await splicePdf(uploadedFile[0], startPage, endPage);

            const result = await loi2loi(splicedFile, owners, requesterName, requesterDesignation, requesterCompany, {
                useOCR,
                useVision,
                useContentCache,
                useLOICache,
                organisationId: user.orgId,
            });
            setResult(result);
            console.log(result);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            <ToolHeader title={"LOI -> LOI"} subtext={"Generate a Letter of Indemnity from another Letter or Indemnity"}></ToolHeader>
            <ToolStepProgressBar
                steps={steps}
                currentStep={currentStep}
                onStepChange={handleStepChange}
            />

            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            {/* PDF Upload Section */}
            <DocumentUploader files={uploadedFile} setFiles={setUploadedFile} title={"Upload LOI PDF"} setResult={setResult}></DocumentUploader>

            {/* Input text fields */}
            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Page Range</Typography>
                <Grid2 container spacing={2}>
                    <Grid2 size={6}>
                        <TextField
                            label="Start Page"
                            type="number"
                            value={startPage}
                            onChange={(e) => {
                                const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                setStartPage(newValue); // Update state only if input is within range or empty
                            }}
                            slotProps={{
                                input: {
                                    min: 1,
                                    max: pageCount,
                                    onInput: (e) => {
                                        const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                        e.target.value = newValue;
                                    },
                                },
                            }}
                            disabled={pageCount === 1}
                            sx={{ mt: 2, width: "100%" }}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <TextField
                            label="End Page"
                            type="number"
                            value={endPage}
                            onChange={(e) => {
                                const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                setEndPage(newValue);
                            }}
                            slotProps={{
                                input: {
                                    min: 1,
                                    max: pageCount,
                                    onInput: (e) => {
                                        const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                        e.target.value = newValue;
                                    },
                                },
                            }}
                            disabled={pageCount === 1}
                            sx={{ mt: 2, width: "100%" }}
                        />
                    </Grid2>
                </Grid2>
                <Typography variant="h6" sx={{ mt: 2 }}>Owners/Managers Information</Typography>
                <Grid2 container spacing={2} sx={{ mt: 3 }}>
                    <Grid2 size={12}>
                        <Typography>To Owners/Managers</Typography>
                        <TextField
                            id="owners"
                            variant="outlined"
                            fullWidth
                            value={owners}
                            onChange={(e) => setOwners(e.target.value)}
                        />
                    </Grid2>
                </Grid2>
                <Divider sx={{ marginY: 2 }} />
            </Box>

            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Requestor Information</Typography>
                <Grid2 container spacing={2} sx={{ mt: 3 }}>
                    <Grid2 size={4}>
                        <Typography>Requestor Name (Company)</Typography>
                        <Select
                            value={requesterCompany}
                            onChange={(e) => setRequesterCompany(e.target.value)}
                            fullWidth
                            variant="outlined"
                        >
                            {availableRequesterOptions.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid2>
                    <Grid2 size={4}>
                        <Typography>Requestor Full Name</Typography>
                        <TextField
                            id="requester-name"
                            variant="outlined"
                            fullWidth
                            value={requesterName}
                            onChange={(e) => setRequesterName(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Typography>Requestor Title</Typography>
                        <TextField
                            id="requester-designation"
                            variant="outlined"
                            fullWidth
                            value={requesterDesignation}
                            onChange={(e) => setRequesterDesignation(e.target.value)}
                        />
                    </Grid2>
                </Grid2>
                <Divider sx={{ marginY: 2 }} />
            </Box>

            {user.isAdmin && (
                <>
                    {/* Advanced Options Toggle */}
                    <Button
                        variant="outlined"
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                    >
                        {showAdvancedOptions
                            ? "Hide Advanced Options"
                            : "Show Advanced Options"}
                    </Button>

                    {/* Advanced Options Section */}
                    <Collapse in={showAdvancedOptions}>
                        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useOCR}
                                        onChange={(e) => setUseOCR(e.target.checked)}
                                    />
                                }
                                label="Use OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useVision}
                                        onChange={(e) => setUseVision(e.target.checked)}
                                    />
                                }
                                label="Use Vision"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useContentCache}
                                        onChange={(e) => setUseContentCache(e.target.checked)}
                                    />
                                }
                                label="Use Content Cache"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useLOICache}
                                        onChange={(e) => setUseLOICache(e.target.checked)}
                                    />
                                }
                                label="Use LOI Cache"
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {/* Submit Button */}
            <Button
                onClick={handleGeneration}
                variant="contained"
                color="primary"
                sx={{ marginTop: "20px" }}
                disabled={!uploadedFile.length === 0}
            >
                Generate LOI from LOI
            </Button>

            {result && (
                <Feedback requestId={result.request_id} type={"LOI2LOI"}></Feedback>
            )}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <LOI2LOIResult
                    response={result}
                    requestorName={requesterName}
                    requestorCompany={requesterCompany}
                    requestorDesignation={requesterDesignation}
                    owners={owners}
                    address={requestorAddress}
                />
            )}
        </Box>
    );
};

export default LOI2LOI;
