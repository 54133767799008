// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === 'production';
const DEV_API_URL = "http://localhost:8000/tools";
// const DEV2_API_URL = "http://51.20.163.64:8000";
// const PRODUCTION_API_URL = "https://centurion.shipster.club/tools";

const getApiBaseUrl = () => {
    return window.location.origin + "/tools"; // Use the same origin as the frontend
};

export const API_URL = isProduction ? getApiBaseUrl() : DEV_API_URL;