import React, { useState, useEffect } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid2,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from 'prop-types';

const CargoResult = ({ response }) => {
    CargoResult.propTypes = {
        response: PropTypes.object.isRequired, // Define the type of response, e.g., object, string, etc.
    };

    const [cargoComparisonResult, setCargoComparisonResult] = useState(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setCargoComparisonResult(response);
    }, [response]);

    if (!cargoComparisonResult) return null;

    const { comparison_result, total_time, pdf_storage_time, cargo_extraction_time, comparison_time } = cargoComparisonResult;

    const lotsWithMissingInfo = comparison_result.cargo_comparison.lot_comparisons.filter(lot =>
        lot.added === true || lot.removed === true
    );

    const checkForChanged = (significance) => {
        switch (significance) {
            case "EXACT_MATCH":
                return false;
            case "MATERIAL_VARIANCE":
                return true;
            case "INSIGNIFICANT_VARIANCE":
                return false;
            default:
                return false;
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h5">Comparison Result</Typography>
            <Button
                variant="text"
                fullWidth
                onClick={() => setExpanded(!expanded)}
                sx={{ mt: 3 }}
                style={{ justifyContent: "flex-start" }}
            >
                {`Reponse time: ${total_time.toFixed(2)} seconds`}
            </Button>
            <Collapse in={expanded}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>Total Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">{total_time.toFixed(2)} seconds</Typography>
                        <Typography sx={{ mb: 1 }}>PDF Storage Time</Typography>
                        <Typography variant="h4">{pdf_storage_time.toFixed(2)} seconds</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>Cargo Extraction Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">{cargo_extraction_time.toFixed(2)} seconds</Typography>
                        <Typography sx={{ mb: 1 }}>Comparison Time</Typography>
                        <Typography variant="h4">{comparison_time.toFixed(2)} seconds</Typography>
                    </Grid2>
                </Grid2>
            </Collapse>

            {/* Comparison Summary */}
            <Grid2 container spacing={2}>
                <Grid2 item size={{ xs: 12 }}>
                    <Typography variant="h6">Summary</Typography>
                    <Typography>Variance Summary: {comparison_result.cargo_comparison.variance_summary}</Typography>
                    <Typography>
                        Missing lots:{" "}
                        {lotsWithMissingInfo.map(item => item.lot).join(", ") || "None"}
                    </Typography>
                </Grid2>

                {/* Voyage Manifest Details */}
                <Grid2 item size={{ xs: 6 }}>
                    {comparison_result.second_set_of_documents.map((manifest, index) => (
                        <Accordion key={index} defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Manifest {index + 1} - {manifest.short_name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Vessel: {manifest.vessel}</Typography>
                                <Typography>Date: {manifest.date}</Typography>

                                {/* Manifest Cargo Details Table */}
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow key={"headers row"}>
                                                <TableCell>Lot</TableCell>
                                                {comparison_result.second_set_of_documents[0].lot_details[0].quantity_metrics.map((metric) => {
                                                    return (
                                                        <TableCell key={metric.metric}>
                                                            {metric.metric}
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell>Description</TableCell>
                                                <TableCell>Port of Loading</TableCell>
                                                <TableCell>Port of Discharge</TableCell>
                                                <TableCell>Page</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {manifest.lot_details
                                                .filter(detail =>
                                                    comparison_result.cargo_comparison.lot_comparisons.some(l => l.lot === detail.lot)
                                                )
                                                .map((detail, detailIndex) => {
                                                    const changeDetail = comparison_result.cargo_comparison.lot_comparisons.find(l => l.lot === detail.lot);
                                                    const missing = lotsWithMissingInfo.some(l => l.lot === detail.lot);
                                                    const quantityMetrics = detail.quantity_metrics || [];

                                                    return (
                                                        <TableRow
                                                            key={`detail-${detailIndex}`}
                                                            style={{ backgroundColor: missing ? 'red' : 'inherit' }}
                                                        >
                                                            <TableCell>{detail.lot}</TableCell>
                                                            {quantityMetrics.map((metric, metricIndex) => {
                                                                const variance = changeDetail.quantity_metrics_variance?.find(m =>
                                                                    m.metric === metric.metric ||
                                                                    (Array.isArray(m.metric_synonyms) && m.metric_synonyms.includes(metric.metric))
                                                                )?.variance;

                                                                return (
                                                                    <TableCell
                                                                        key={`metric-${metric.metric}-${metricIndex}`}
                                                                        style={{
                                                                            backgroundColor: checkForChanged(variance) ? 'orange' : 'inherit',
                                                                        }}
                                                                    >
                                                                        {metric.value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                            <TableCell
                                                                style={{
                                                                    backgroundColor: checkForChanged(changeDetail.content_description_variance) ? 'grey' : 'inherit',
                                                                }}
                                                            >
                                                                {detail.description}
                                                            </TableCell>
                                                            <TableCell>{detail.port_of_loading}</TableCell>
                                                            <TableCell>{detail.port_of_discharge}</TableCell>
                                                            <TableCell>{detail.page}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid2>

                {/* Bill of Lading Details */}
                <Grid2 item size={{ xs: 6 }}>
                    {comparison_result.first_set_of_documents.map((bl, index) => (
                        <Accordion key={`bl: ${index}`} defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Bill of Lading Details ({bl.short_name})
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Vessel: {bl.vessel}</Typography>
                                <Typography>Date: {bl.date}</Typography>

                                {/* Cargo Details Table */}
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Lot</TableCell>
                                                {bl.lot_details[0].quantity_metrics.map((metric) => {
                                                    return (
                                                        <TableCell key={`table cell: ${metric.metric}`}>
                                                            {metric.metric}
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell>Description</TableCell>
                                                <TableCell>Port of Loading</TableCell>
                                                <TableCell>Port of Discharge</TableCell>
                                                <TableCell>Page</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bl?.lot_details
                                                .filter(detail =>
                                                    comparison_result.cargo_comparison.lot_comparisons.some(l => l.lot === detail.lot)
                                                )
                                                .map((detail, detailIndex) => {
                                                    const changeDetail = comparison_result.cargo_comparison.lot_comparisons.find(l => l.lot === detail.lot);
                                                    const missing = lotsWithMissingInfo.some(l => l.lot === detail.lot);
                                                    const quantityMetrics = detail.quantity_metrics || [];

                                                    return (
                                                        <TableRow
                                                            key={`detail-${detailIndex}`}
                                                            style={{ backgroundColor: missing ? 'red' : 'inherit' }}
                                                        >
                                                            <TableCell>{detail.lot}</TableCell>
                                                            {quantityMetrics.map((metric, metricIndex) => {
                                                                const variance = changeDetail.quantity_metrics_variance?.find(m =>
                                                                    m.metric === metric.metric ||
                                                                    (Array.isArray(m.metric_synonyms) && m.metric_synonyms.includes(metric.metric))
                                                                )?.variance;

                                                                return (
                                                                    <TableCell
                                                                        key={`metric-${metric.metric}-${metricIndex}`}
                                                                        style={{
                                                                            backgroundColor: checkForChanged(variance) ? 'orange' : 'inherit',
                                                                        }}
                                                                    >
                                                                        {metric.value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                            <TableCell
                                                                style={{
                                                                    backgroundColor: checkForChanged(changeDetail.content_description_variance) ? 'grey' : 'inherit',
                                                                }}
                                                            >
                                                                {detail.description}
                                                            </TableCell>
                                                            <TableCell>{detail.port_of_loading}</TableCell>
                                                            <TableCell>{detail.port_of_discharge}</TableCell>
                                                            <TableCell>{detail.page}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid2>

            </Grid2>
        </Box>
    );
};

export default CargoResult;
