export const getLoiCTemplate = ({
    shipper,
    consignee,
    requestorParty,
    deliveryParty,
    deliveryPlace,
    requestorName,
    requestorDesignation
}) => {
    return {
        templateLabel: "No Bill and Other Port",

        cargoDescription: `The above Cargo was shipped on the above Vessel by ${shipper} and consigned to ${consignee} for delivery at the Port of Discharge but we, ${requestorParty} , hereby request you to order the Vessel to proceed to and deliver the said cargo at ${deliveryPlace}`,

        indemnityRequest: `Further, as the Bill(s) of Lading is (are) not currently available to be presented, we also hereby represent and undertake that ${deliveryParty} is the party lawfully entitled to delivery of the said Cargo and request you to deliver the said Cargo to ${deliveryParty} or to such party as you believe to be or to represent ${deliveryParty} or to be acting on behalf of ${deliveryParty} at ${deliveryPlace} without production of the original Bill(s) of Lading.`,

        indemnityTerms: "In consideration of your complying with either or both of our above requests, we hereby agree as follows:",

        indemnityAgreement: [
            "1. To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of delivering the Cargo in accordance with our request.",
            "2. In the event of any proceedings being commenced against you or any of your servants or agents in connection with the delivery of the Cargo as aforesaid, to provide you or them on demand with sufficient funds to defend the same.",
            "3. If, in connection with the delivery of the Cargo as aforesaid, the Vessel, or any other vessel or property in the same or associated ownership, management or control, or any vessel or property in your ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the Vessel or such other vessels or property (whether by virtue of a caveat being entered on the Vessel's or such other vessel's registry or otherwise howsoever):",
            "4. If the place at which we have asked you to make delivery is a bulk liquid, dry bulk cargo or gas terminal or other facility, or another vessel, lighter or barge, then discharge or delivery to such terminal, facility, vessel, lighter or barge shall be deemed to be delivery to the party to whom we have requested you to make such delivery.",
            "5. As soon as all original bills of lading for the above Cargo shall have come into our possession, to deliver the same to you.",
            "6. The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.",
            "7. This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity submits to the exclusive jurisdiction of the High Court of Justice of England.*1"
        ],

        indemnitySublist: [
            "(a) to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of the Vessel or such other vessel or property or to remove such interference;",
            "(b) if you have already provided security, to provide on demand equivalent substitute or counter security, whether or not you have made any prior demand upon us and whether or not such security exceeds the value of the Vessel or such other vessel, and",
            "(c) to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified."
        ],

        signatureText: [
            "Yours faithfully,",
            `For and on behalf of ${requestorParty}`,
            "The Requestor",
            "Signature …………………………………",
            `Full name: ${requestorName}`,
            `Title: ${requestorDesignation}`
        ]
    };

};
