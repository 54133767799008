import React from 'react';
import PropTypes from 'prop-types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const GradientAutoAwesomeIcon = ({ fontSize = 'inherit' }) => (
    <>
        {/* Define the gradient */}
        <svg width={0} height={0}>
            <linearGradient id="ai-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#3D86F6" />
                <stop offset="100%" stopColor="#31BAAE" />
            </linearGradient>
        </svg>
        {/* Render the icon with the gradient and dynamic font size */}
        <AutoAwesomeIcon
            sx={{
                fill: 'url(#ai-gradient)',
                fontSize: fontSize, // Adjust size based on parent or prop
            }}
        />
    </>
);

// PropTypes for validation
GradientAutoAwesomeIcon.propTypes = {
    fontSize: PropTypes.oneOfType([
        PropTypes.string, // e.g., "small", "large", "inherit"
        PropTypes.number, // e.g., 24, 32 (numeric pixel sizes)
    ]),
};

export default GradientAutoAwesomeIcon;
