// Define a style for the modal box
export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%", // To limit height and allow scroll
    bgcolor: "background.paper",
    border: "1px solid #ccc", // Light border
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Softer shadow
    display: "flex",
    flexDirection: "column", // Ensures sticky footer and scrolling content
    p: 4,
};

export const contentStyle = {
    flex: 1,
    overflowY: "auto", // Allow vertical scroll
    padding: "16px",
    marginBottom: "60px", // Space for sticky buttons
};