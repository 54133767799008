import axios from "axios";
import { API_URL } from "./config";

export const extractDetailedLoiFromPdf = async (
    file, requestorName, requestorFullName,
    options = {
        useOCR: true,
        useVision: true,
        useContentCache: true,
        organisationId: "", // you can set a default organisation ID if needed
    }
) => {
    const formData = new FormData();
    formData.append('bill_of_lading', file);  // The file object (PDF)
    formData.append("ocr", options.useOCR);
    formData.append("vision", options.useVision);
    formData.append("use_cache", options.useContentCache);
    formData.append('requestor_name', requestorName);  // Requester name
    formData.append('requestor_full_name', requestorFullName);  // Requester's full name
    formData.append("organisation_id", options.organisationId);

    try {
        const response = await axios.post(`${API_URL}/bl2loi/extract_detailed_loi_from_pdf`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
    } catch (error) {
        console.error('Error extracting LOI:', error);
        throw error;
    }
};
