import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun, AlignmentType, Footer, Header, ImageRun } from "docx";
import { toTitleCase } from '../helpers';
import { getTemplateFunctions } from '../templates/utils';

// Indemnity agreement text
const indemnityText = [
    "To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of making the requested amendment(s).",
    "In the event of any proceedings being commenced against you or any of your servants or agents in connection with making the requested amendment(s) as aforesaid, to provide you or them on demand with sufficient funds to defend the same.",
    "If, in connection with the delivery of the Cargo as aforesaid, the Vessel, or any other vessel or property in the same or associated ownership, management or control, or any vessel or property in your ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the Vessel or such other vessels or property (whether by virtue of a caveat being entered on the Vessel's or such other vessel's registry or otherwise howsoever):",
    "If the place at which we have asked you to make delivery is a bulk liquid, dry bulk cargo or gas terminal or other facility, or another vessel, lighter or barge, then discharge or delivery to such terminal, facility, vessel, lighter or barge shall be deemed to be delivery to the party to whom we have requested you to make such delivery.",
    "As soon as all original bill of lading for the above Cargo shall have come into our possession, to deliver the same to you, whereupon (always provided that the said bill of lading have been properly tendered by the party to whom the Cargo was actually delivered) our liability hereunder shall cease.",
    "The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.",
    "This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity submits to the exclusive jurisdiction of the High Court of Justice of England.",
];

// Sublist to appear under the third item
const indemnitySublist = [
    "to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of the Vessel or such other vessel or property or to remove such interference;",
    "if you have already provided security, to provide on demand equivalent substitute or counter security, whether or not you have made any prior demand upon us and whether or not such security exceeds the value of the Vessel or such other vessel, and",
    "to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.",
];

// Create the list of paragraphs with sublist
const indemnityList = indemnityText.map((text, index) => {
    // If it's the third paragraph (index 2), add the sublist after it
    if (index === 2) {
        // Create the main list item
        const mainParagraph = new Paragraph({
            children: [new TextRun(text)],
            spacing: {
                after: 100,  // 100 twips after the paragraph
            },
            numbering: {
                reference: "indemnity-numbering",
                level: 0, // Main list level
            },
        });

        // Create the sublist items (a, b, c)
        const sublistParagraphs = indemnitySublist.map((subItem) => {
            return new Paragraph({
                children: [new TextRun(subItem)],
                spacing: {
                    after: 100,  // 100 twips after the paragraph
                },
                numbering: {
                    reference: "indemnity-numbering",
                    level: 1, // Sublist level
                },
            });
        });

        // Return both the main paragraph and the sublist
        return [mainParagraph, ...sublistParagraphs];
    } else {
        // Return a normal main list item for other paragraphs
        return new Paragraph({
            children: [new TextRun(text)],
            spacing: {
                after: 100,  // 100 twips after the paragraph
            },
            numbering: {
                reference: "indemnity-numbering",
                level: 0,
            },
        });
    }
}).flat(); // Flatten the array to avoid nested arrays


const strongText = (text) => new TextRun({ text, bold: true });

// Function to convert an image as Base64
export const imageToBase64 = async (imagePath) => {
    const response = await fetch(imagePath); 
    const blob = await response.blob(); 
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); 
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};


export const handleSaveAsWord = async (formData) => {
    const {
        currentDate, vessel, owners, ownersManagers, portLoading, portDischarge,
        cargo, billOfLading, consignee, deliveryParty, shipper, requestorParty,
        requestorName, requestorDesignation, deliveryPlace, address, letterhead, loiTemplate
    } = formData;

    const templateText = await getTemplateFunctions[loiTemplate.type]({
        shipper,
        consignee,
        requestorParty,
        deliveryParty,
        deliveryPlace,
        requestorName,
        requestorDesignation,
    });
    const { indemnityTerms, indemnityAgreement, indemnitySublist } = templateText;

    // Helper function to create a Paragraph
    const createParagraph = (textRuns, spacingAfter = 100) => {
        return new Paragraph({
            children: textRuns.map((text) =>
                typeof text === "string" ? new TextRun({ text }) : strongText(text)
            ),
            spacing: { after: spacingAfter },
        });
    };

    const getCargoDescriptionParagraph = () => {
        if (!loiTemplate) return null;

        switch (loiTemplate.type) {
            case "A":
                return new Paragraph({
                    children: [
                        new TextRun({ text: 'The above Cargo was shipped on the above Vessel by ' }),
                        strongText(`${shipper}`),
                        new TextRun({ text: ' and consigned to ' }),
                        strongText(`${consignee}`),
                        new TextRun({ text: ' for delivery at the Port of Discharge but the Bill(s) of Lading is (are) not currently available to be presented.' }),
                    ],
                    spacing: {
                        after: 100,
                    },
                })
            case "B":
                return new Paragraph({
                    children: [
                        new TextRun({ text: 'The above Cargo was shipped on the above Vessel by ' }),
                        strongText(`${shipper}`),
                        new TextRun({ text: ' and consigned to ' }),
                        strongText(`${consignee}`),
                        new TextRun({ text: ' for delivery at the Port of Discharge but we, ' }),
                        strongText(`${requestorParty}`),
                        new TextRun({ text: ', hereby request you to order the Vessel to proceed to and deliver the said cargo at ' }),
                        strongText(`${deliveryPlace}`),
                        new TextRun({ text: ' against production of at least one original Bill(s) of Lading.' }),
                    ],
                    spacing: {
                        after: 0,
                    },
                })
            case "C":
                return new Paragraph({
                    children: [
                        new TextRun({ text: 'The above Cargo was shipped on the above Vessel by ' }),
                        strongText(`${shipper}`),
                        new TextRun({ text: ' and consigned to ' }),
                        strongText(`${consignee}`),
                        new TextRun({ text: ' for delivery at the Port of Discharge but we, ' }),
                        strongText(`${requestorParty}`),
                        new TextRun({ text: ', hereby request you to order the Vessel to proceed to and deliver the said cargo at ' }),
                        strongText(`${deliveryPlace}`),
                        new TextRun({ text: '.' }),
                    ],
                    spacing: {
                        after: 100,
                    },
                })
            default:
                return createParagraph([""])
        }
    }

    const getIndemnityRequestParagraph = () => {
        if (!loiTemplate) return null;
        
        switch (loiTemplate.type) {
            case "A":
                return new Paragraph({
                    children: [
                        new TextRun({ text: 'We, ' }),
                        strongText(`${requestorParty}`),
                        new TextRun({ text: ', hereby represent and undertake that ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' is the party lawfully entitled to delivery of the said Cargo and request you to deliver the said Cargo to ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' or to such party as you believe to be or to represent ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' or to be acting on behalf of ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' at ' }),
                        strongText(`${deliveryPlace}`),
                        new TextRun({ text: ' without production of the original Bill(s) of Lading.' }),
                    ],
                    spacing: {
                        after: 100,
                    },
                })
            case "C":
                return new Paragraph({
                    children: [
                        new TextRun({ text: 'Further, as the Bill(s) of Lading is (are) not currently available to be presented, we also hereby represent and undertake that ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' is the party lawfully entitled to delivery of the said Cargo and request you to deliver the said Cargo to ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' or to such party as you believe to be or to represent ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' or to be acting on behalf of ' }),
                        strongText(`${deliveryParty}`),
                        new TextRun({ text: ' at ' }),
                        strongText(`${deliveryPlace}`),
                        new TextRun({ text: ' without production of the original Bill(s) of Lading.' }),
                    ],
                    spacing: {
                        after: 100,
                    },
                });
            default:
                return createParagraph([""])
        }
    }

    const getIndemnityList = (indemnityAgreement) => {
        return indemnityAgreement.map((text, index) => {
            // If it's the third paragraph (index 2), add the sublist after it
            if (index === 2) {
                // Create the main list item
                const mainParagraph = createParagraph([text])

                // Create the sublist items (a, b, c)
                const sublistParagraphs = indemnitySublist.map((subItem) => {
                    return new Paragraph({
                        children: [new TextRun(subItem)],
                        spacing: {
                            after: 100,  // 100 twips after the paragraph
                        },
                        indent: {
                            left: 720,
                        },
                    });
                });

                // Return both the main paragraph and the sublist
                return [mainParagraph, ...sublistParagraphs];
            } else {             
                // Return a normal main list item for other paragraphs
                return createParagraph([text])
            }
        }).flat();
    };

    const logoBase64 = await imageToBase64(letterhead);
    const doc = new Document({
        numbering: {
            config: [
                {
                    reference: "indemnity-numbering",
                    levels: [
                        {
                            level: 0,
                            format: "decimal",
                            text: "%1.",
                            alignment: "left",
                        },
                        {
                            level: 1,
                            format: 'lowerLetter', // Sublist uses letters (a, b, c)
                            text: '(%2)', // Wrap the letter in parentheses
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: { left: 1440, hanging: 260 }, // Adjust indentation for sublist
                                },
                            },
                        },
                    ],
                },
            ],
        },
        sections: [
            {
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                children: [
                                    new ImageRun({
                                        type: 'png',
                                        data: logoBase64,
                                        transformation: {
                                            width: 300,
                                            height: 80,
                                        },
                                    })
                                ],
                                alignment: 'right',
                            })
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: "center",
                                children: [
                                    new TextRun({
                                        text: `${requestorParty}`,
                                        size: 18,
                                        color: "888888",
                                    }),
                                ],
                            }),
                            new Paragraph({
                                alignment: "center",
                                children: [
                                    new TextRun({
                                        text: `${address}`,
                                        size: 18,
                                        color: "888888",
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({ text: "To: " }),
                            strongText(`\t${ownersManagers}`),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: 'and/or The Owners and/or Managers of the ' }),
                            strongText(`${vessel}`),
                        ],
                        spacing: {
                            after: 200,
                        },
                        indent: {
                            left: 720, // Add an indent to match the layout
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "Date:" }),
                            strongText(`\t${currentDate}`),
                        ],
                        spacing: {
                            before: 300, // Space before the date line
                            after: 200,  // Space after the date line
                        },
                    }),
                    new Paragraph({ text: 'Dear Sirs' }),
                    new Paragraph({
                        children: [
                            new TextRun("Vessel:  "),
                            strongText(`${vessel}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Port of Loading:  "),
                            strongText(`${portLoading}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Port of Discharge:  "),
                            strongText(`${portDischarge}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Cargo:  "),
                            strongText(`${cargo}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Bill(s) of Lading:  "),
                            strongText(`${billOfLading}`),
                        ],
                        spacing: {
                            after: 100,
                        },
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    getCargoDescriptionParagraph(),
                    getIndemnityRequestParagraph(),
                    new Paragraph({
                        children: [
                            new TextRun({ text: indemnityTerms })
                        ],
                        spacing: {
                            after: 200,
                        },
                    }),
                    ...getIndemnityList(indemnityAgreement),
                    new Paragraph({ text: `For and on behalf of ${requestorParty}` }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "The Requestor" })
                        ],
                        spacing: {
                            after: 200,
                        }
                    }),
                    new Paragraph({ text: 'Signature …………………………………' }),
                    new Paragraph({ text: `Full name: ${requestorName}` }),
                    new Paragraph({ text: `Title: ${requestorDesignation}` }),
                ],
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `LOI_${owners}_${loiTemplate.type}.docx`);
    });
};

export const handleSaveAsWordMRLOI = async (formData) => {
    const {
        currentDate, vessel, owners, portLoading, portDischarge,
        shipper, requestorName, address, changes, holdChanges, letterhead
    } = formData;

    const strongText = (text) => new TextRun({ text, bold: true });

    // Create the first list of changes
    const changeList = Object.keys(changes).length > 0 ?
        changes.map((change) => {
            return new Paragraph({
                children: [
                    new TextRun("Change "),
                    strongText(`"${change.field}"`),
                    new TextRun(" from "),
                    strongText(`"${change.original}"`),
                    new TextRun(" to "),
                    strongText(`"${change.updated}"`),
                ],
                numbering: {
                    reference: "change-numbering",
                    level: 0,
                },
                spacing: {
                    after: 100,  // 100 twips after the paragraph
                },
            });
        }) : []; // No paragraphs if no changes;

    // Create the second list for hold changes
    const holdChangeList = holdChanges.map((change) => {
        return new Paragraph({
            children: [
                new TextRun("Change "),
                strongText(`"${toTitleCase(change.field)}"`),
                new TextRun(" from "),
                strongText(`"${change.original}"`),
                new TextRun(" to "),
                strongText(`"${change.updated}"`),
            ],
            numbering: {
                reference: "change-numbering",
                level: 0,
            },
            spacing: {
                after: 100,  // 100 twips after the paragraph
            },
        });
    }).flat().filter(Boolean);

    const logoUrl = await imageToBase64(letterhead);

    const doc = new Document({
        numbering: {
            config: [
                {
                    reference: "change-numbering",
                    levels: [
                        {
                            level: 0,
                            format: "decimal",
                            text: "%1.",
                            alignment: "left",
                        },
                    ],
                },
                {
                    reference: "indemnity-numbering",
                    levels: [
                        {
                            level: 0,
                            format: "decimal",
                            text: "%1.",
                            alignment: "left",
                        },
                        {
                            level: 1,
                            format: 'lowerLetter', // Sublist uses letters (a, b, c)
                            text: '(%2)', // Wrap the letter in parentheses
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: { left: 1440, hanging: 260 }, // Adjust indentation for sublist
                                },
                            },
                        },
                    ],
                },
            ],
        },
        sections: [
            {
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: logoUrl.split(",")[1], // Get the Base64 part
                                        transformation: {
                                            width: 300,
                                            height: 80,
                                        },
                                    })
                                ],
                                alignment: 'right',
                            })
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: "center",
                                children: [
                                    new TextRun({
                                        text: `${address}`,
                                        size: 18,
                                        color: "888888",
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({ text: "To:" }),
                            strongText(`\t${owners}`),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "Date:" }),
                            strongText(`\t${currentDate}`),
                        ],
                        spacing: {
                            before: 300, // Space before the date line
                            after: 200,  // Space after the date line
                        },
                    }),
                    new Paragraph({ text: 'Dear Sirs' }),
                    new Paragraph({
                        children: [
                            new TextRun("Vessel:  "),
                            strongText(`${vessel}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Port of Loading:  "),
                            strongText(`${portLoading}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Port of Discharge:  "),
                            strongText(`${portDischarge}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("Shipper: "),
                            strongText(`${shipper}`),
                        ],
                        indent: {
                            left: 720,
                            right: 0,
                        },
                        alignment: 'left',
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "We hereby request you to amend the Mate's Receipt as follows:" })
                        ],
                        spacing: {
                            after: 200,
                            before: 200,
                        },
                    }),
                    ...changeList,
                    ...holdChangeList,
                    new Paragraph({
                        children: [
                            new TextRun({ text: 'In consideration of your complying with our above request, we hereby agree as follows:' })
                        ],
                        spacing: {
                            after: 200,
                            before: 100,
                        },
                    }),
                    ...indemnityList,
                    new Paragraph({
                        children: [
                            new TextRun({ text: "Yours faithfully," })
                        ],
                        spacing: {
                            before: 400,
                        }
                    }),
                    new Paragraph({ text: 'For and on behalf of' }),
                    new Paragraph({ text: `${requestorName}` }),
                    new Paragraph({ text: `${address}` }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "The Requestor" })
                        ],
                        spacing: {
                            after: 200,
                        }
                    }),
                    new Paragraph({ text: '…………………………………' }),
                    new Paragraph({ text: 'Signature' }),
                    new Paragraph({ text: `Name: ${requestorName}` }),
                ],
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `MR_Ammendment_LOI_${owners}.docx`);
    });
};

