import axios from "axios";
import { API_URL } from "./config";

export const sendUserFeedback = async (
    orgId, requestId, accepted, feedback, toolName
) => {
    const formData = new FormData();
    formData.append('organisation_id', orgId);
    formData.append("request_id", requestId);
    formData.append('comparison_accepted', accepted);
    formData.append("open_feedback", feedback);
    formData.append("tool_name", toolName);

    try {
        const response = await axios.post(`${API_URL}/feedback/submit_user_feedback`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
    } catch (error) {
        console.error('Error extracting response:', error);
        throw error;
    }
};

export const sendFieldFeedback = async (
    orgId, requestId, fields, toolName
) => {
    // Create the payload as a JSON object
    const payload = {
        organisation_id: orgId,
        request_id: requestId,
        updated_fields: fields, // Ensure `fields` is already a JSON-compatible object
        tool_name: toolName
    };

    try {
        const response = await axios.post(`${API_URL}/feedback/submit_field_feedback`, payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
    } catch (error) {
        console.error('Error sending feedback:', error);
        throw error;
    }
};

export const sendToggleFeedback = async (
    orgId, requestId, toggles, toolName
) => {
    // Create the payload as a JSON object
    const payload = {
        organisation_id: orgId,
        request_id: requestId,
        toggles: toggles,
        tool_name: toolName
    };

    try {
        const response = await axios.post(`${API_URL}/feedback/submit_toggle_feedback`, payload, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
    } catch (error) {
        console.error('Error extracting response:', error);
        throw error;
    }
};