import React, { useState, useEffect, useCallback, useContext } from 'react';
import html2pdf from "html2pdf.js";
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    Switch,
    Grid2,
    InputLabel,
    OutlinedInput,
    Typography,
    Modal,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    IconButton,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { toSnakeCase, toTitleCase } from '../../helpers';
import { handleSaveAsWordMRLOI } from "../../services/loiDocxService"
import { letterheadOptions } from "../../config/loginDetails"
import { sendFieldFeedback, sendToggleFeedback } from "../../services/feedbackService";
import { UserContext } from '../../context/UserContext';
import PropTypes from 'prop-types';

const checkForChanged = (significance) => {
    switch (significance) {
        case "EXACT_MATCH":
            return false;
        case "MATERIAL_VARIANCE":
            return true;
        case "INSIGNIFICANT_VARIANCE":
            return true;
        default:
            return false;
    }
};

const getStyle = (significance) => {
    switch (significance) {
        case "EXACT_MATCH":
            return { color: "inherit" };
        case "MATERIAL_VARIANCE":
            return { color: "red" };
        case "INSIGNIFICANT_VARIANCE":
            return { color: "orange" };
        default:
            return { color: "inherit" };
    }
};

const MatesReceiptsResult = ({ response }) => {
    MatesReceiptsResult.propTypes = {
        response: PropTypes.object.isRequired, 
    };

    const [mrComparisonResult, setMrComparisonResult] = useState(null);
    const [expanded2, setExpanded2] = useState(false);

    useEffect(() => {
        setMrComparisonResult(response);
    }, [response]);

    if (!mrComparisonResult) return null;

    const { comparison_result, total_time, pdf_storage_time, mr_extraction_time, comparison_time, request_id } = mrComparisonResult;

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h5">Comparison Result</Typography>
            <Button
                variant="text"
                fullWidth
                onClick={() => setExpanded2(!expanded2)}
                sx={{ mt: 3 }}
                style={{ justifyContent: "flex-start" }}
            >
                {`Reponse time: ${total_time?.toFixed(2)} seconds`}
            </Button>
            <Collapse in={expanded2}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>Total Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">{total_time.toFixed(2)} seconds</Typography>
                        <Typography sx={{ mb: 1 }}>PDF Storage Time</Typography>
                        <Typography variant="h4">{pdf_storage_time.toFixed(2)} seconds</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>MR Extraction Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">{mr_extraction_time.toFixed(2)} seconds</Typography>
                        <Typography sx={{ mb: 1 }}>Comparison Time</Typography>
                        <Typography variant="h4">{comparison_time.toFixed(2)} seconds</Typography>
                    </Grid2>
                </Grid2>
            </Collapse>
            <MRStaticDetail
                doc1={comparison_result.original_MR}
                doc2={comparison_result.updated_MR}
                comparison={comparison_result.mr_comparison}
                titles={['Original MR', 'Updated MR']}>
            </MRStaticDetail>
            <MRAmendmentForm
                doc1={comparison_result.original_MR}
                doc2={comparison_result.updated_MR}
                comparison={comparison_result.mr_comparison}
                requestId={request_id} />
        </Box>
    );
};

export const MRStaticDetail = ({ doc1, doc2, comparison, titles }) => {
    MRStaticDetail.propTypes = {
        doc1: PropTypes.object.isRequired, 
        doc2: PropTypes.object.isRequired, 
        comparison: PropTypes.object,
        titles: PropTypes.array,
    };

    const [expanded, setExpanded] = useState(true);
    if (!comparison) return null;
    return (
        <Card>
            <CardContent>
                <Typography variant="h5" sx={{ mb: 1 }}>Variance Summary</Typography>
                <Typography>{comparison.variance_summary}</Typography>

                <Divider sx={{ marginY: 2 }} />

                <Button
                    variant="text"
                    fullWidth
                    onClick={() => setExpanded(!expanded)}
                    sx={{ my: 2, justifyContent: "flex-start", typography: 'h5', textTransform: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}
                >
                    MR Details
                    <ExpandMoreIcon
                        sx={{
                            marginLeft: 'auto',
                            transition: 'transform 0.3s',
                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)'
                        }}
                    />
                </Button>

                <Collapse in={expanded}>
                    <Grid2 container spacing={2}>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="h6">{titles[0]}</Typography>
                            {renderMRDetails(doc1, comparison)}
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6 }}>
                            <Typography variant="h6">{titles[1]}</Typography>
                            {renderMRDetails(doc2, comparison)}
                        </Grid2>
                    </Grid2>
                </Collapse>
                <Divider sx={{ marginY: 2 }} />
            </CardContent>
        </Card>
    );
}

const renderMRDetails = (mr, comparison) => {
    return (
        <div>
            {["short_name", "date", "mr_id", "vessel", "port_of_loading", "port_of_discharge", "shipper", "consignee", "shipping_marks", "shipper_reference"].map(field => {
                const value = mr[field];
                var change = comparison[`${field}_change`];
                if (!change) {
                    change = { changed: false, significant_variance: "EXACT_MATCH" }
                }
                if ((field === "shipper_reference" || field === "mr_id") && !value) return null;
                return (
                    <Typography key={field} style={getStyle(change.significant_variance)}>
                        <strong>{toTitleCase(field)}: </strong>{value}
                        {change.significance_reasoning && ( // Only show Tooltip if significance_reasoning exists
                            <Tooltip title={change.significance_reasoning}>
                                <IconButton sx={{ color: 'gray', opacity: 0.5 }}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Typography>
                );
            })}
            {renderHoldOrLotDetails(mr, comparison)}
        </div>
    );
};

const renderHoldDetail = (hold, holdChange) => {
    return (
        <Box sx={{ border: '1px solid #ccc', padding: 2, margin: 1 }}>
            <Typography variant="h6">Hold: {hold.hold}</Typography>
            {/* TODO: need to handle quantity_metrics array display */}
            {["hold_id", "content_description", "quantity_description"].map(field => {
                const value = hold[field];
                const changeValue = holdChange ? holdChange[`${field}_variance`] : null; // Get change info
                return (
                    <Typography key={field} sx={{ color: checkForChanged(changeValue) ? getStyle(changeValue) : 'inherit' }}>
                        <strong>{toTitleCase(field)}: </strong>{value}
                        {holdChange.significance_reasoning && changeValue && ( // Only show Tooltip if significance_reasoning exists
                            <Tooltip title={holdChange.significance_reasoning}>
                                <IconButton sx={{ color: 'gray', opacity: 0.5 }}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Typography>
                );
            })}
            <Typography><strong>Quantity Metrics: </strong></Typography>
            <List sx={{ listStyleType: 'disc', paddingLeft: 2 }}>
                {hold["quantity_metrics"].map(metric => {
                    const variance = holdChange["quantity_metrics_variance"].find(m => m.metric === metric.metric);
                    const changeValue = variance ? variance.variance : null; // Get change info
                    return (
                        <ListItem key={`list: ${metric.metric}`} sx={{ display: "list-item", color: checkForChanged(changeValue) ? getStyle(changeValue) : "inherit" }}>
                            <strong>{toTitleCase(metric.metric)}: </strong>{metric.value}
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

const renderHoldOrLotDetails = (mr, comparison) => {
    if (mr.hold_details) {
        return (
            <div key={"holdContainer"}>
                <Typography variant="h6">Hold Details</Typography>
                {mr.hold_details.map(hold => {
                    const holdChange = comparison.hold_changes.find(h => h.hold === hold.hold);
                    return (
                        <div key={hold.hold}>
                            {renderHoldDetail(hold, holdChange)}
                        </div>
                    );
                })}
            </div>
        );
    } else if (mr.hold_or_lots === 'lots' && mr.lot_details) {
        return (
            <div>
                <Typography variant="h6">Lot Details</Typography>
                {mr.lot_details.map(lot => {
                    const lotChange = comparison.lot_changes.find(l => l.lot === lot.lot);
                    return (
                        <Box key={lot.lot} sx={{ border: '1px solid #ccc', padding: 2, margin: 1 }}>
                            <Typography variant="h6">Lot: {lot.lot}</Typography>
                            {["description", "pieces", "volume"].map(field => {
                                const value = lot[field];
                                return (
                                    <Typography key={field} sx={{ color: checkForChanged(lotChange?.[`${field}_variance`]) ? 'orange' : 'inherit' }}>
                                        <strong>{field.charAt(0).toUpperCase() + field.slice(1)}: </strong>{value}
                                    </Typography>
                                );
                            })}
                        </Box>
                    );
                })}
            </div>
        );
    }
    return null;
};

export const MRAmendmentForm = ({ doc1, doc2, comparison, requestId}) => {
    MRAmendmentForm.propTypes = {
        doc1: PropTypes.object.isRequired, 
        doc2: PropTypes.object.isRequired, 
        comparison: PropTypes.object,
        requestId: PropTypes.string,
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [requestor, setRequestor] = useState("Lucas X. Meneses");
    const [address, setAddress] = useState("Centurion Bulk Pte. Ltd\n80 Robinson Road #14-02, Singapore 068898");
    const [owners, setOwners] = useState(doc2.disponent_owners || "Centurion Shipping Ltd");
    const [vesselName, setVesselName] = useState(doc2.vessel);
    const [portLoading, setPortLoading] = useState(doc2.port_of_loading);
    const [portDischarge, setPortDischarge] = useState(doc2.port_of_discharge);
    const [shipper, setShipper] = useState(doc2.shipper);
    const [selectedLetterhead, setSelectedLetterhead] = useState(letterheadOptions[0]?.path);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { user } = useContext(UserContext);

    const checkForSignificance = (significance) => {
        if (significance === "MATERIAL_VARIANCE") return true;
        else return false;
    };

    // const fieldsToAmend = Object.entries(comparison).some(([, change]) => checkForChanged(change.significant_variance));

    const fieldsToAmend = Object.entries(comparison).some(([, change]) => checkForChanged(change.significant_variance)) 
    || comparison.hold_changes.some(holdChange =>
        checkForSignificance(holdChange.content_description_variance) ||
        checkForSignificance(holdChange.quantity_description_variance) 
    );

    // State to track enabled/disabled status of each field
    const [fieldStates, setFieldStates] = useState(
        Object.fromEntries(Object.keys(comparison).map((key) => [key, checkForSignificance(comparison[key]["significant_variance"])]))
    );

    // Initialize state based on the length of MRcomparison.hold_changes
    const [holdFieldStates, setHoldFieldStates] = useState(
        comparison.hold_changes.map((_holdChange, index) => ({
            "content_description": checkForSignificance(comparison.hold_changes[index]["content_description_variance"]),
            "quantity_description": checkForSignificance(comparison.hold_changes[index]["quantity_description_variance"]),
            "quantity_metrics": true,
            "hold_id": true
        }))
    );

    const toggleFieldState = (index, field) => {
        setHoldFieldStates((prevState) =>
            prevState.map((item, i) =>
                i === index ? { ...item, [field]: !item[field] } : item
            )
        );
    };

    const checkForHoldChange = (holdChange) => {
        const descriptionSign = holdChange.content_description_variance;
        const quantitySign = holdChange.quantity_description_variance;
        const metricsSign = holdChange.quantity_metrics_variance;
        if (checkForChanged(descriptionSign)) return true;
        if (checkForChanged(quantitySign)) return true;
        if (checkForChanged(metricsSign)) return true;
        return false
    };

    const changedFieldsWithTrueState = Object.entries(comparison)
        .filter(([key, value]) => checkForChanged(value.significant_variance) && fieldStates[key])
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

    // Collect all changed hold fields with true state as an array of field names
    const changedHoldFieldsWithTrueState = comparison.hold_changes
        .map((holdChange, index) => {
            return ["hold_id", "content_description", "quantity_description"]
                .filter(field => checkForChanged(holdChange[`${field}_variance`]) && holdFieldStates[index][field])
                .map(field => ({ field })); // Return the field name and associated hold_id
        })
        .filter(item => item !== undefined); // Filter out any undefined values

    // Handle switch toggle
    const handleSwitchChange = (key) => {
        setFieldStates((prevStates) => ({
            ...prevStates,
            [key]: !prevStates[key]
        }));
    };

    // Handle Generate MR Amendment button click
    const handleGenerateAmendment = () => {
        createAndSendToggleFeedback();
        setIsModalOpen(true);
    };

    const createAndSendToggleFeedback = async () => {
        // Combine fieldStates and holdFieldStates into a single dictionary
        const toggles = { ...fieldStates }; // Start with fieldStates

        // Add holdFieldStates to the dictionary with appropriate keys
        holdFieldStates.forEach((hold, index) => {
            Object.keys(hold).forEach((field) => {
                toggles[`hold_${index}_${field}`] = hold[field];
            });
        });

        console.log(toggles);

        try {
            const result = await sendToggleFeedback(user.orgId, requestId, toggles, "MR");
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Card>
            <CardContent>
                {fieldsToAmend ? (
                    <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5">Generate LOI for MR Amendment</Typography>
                            <IconButton onClick={() => setDialogOpen(true)}>
                                <SettingsIcon />
                            </IconButton>
                        </Box>
                        {/* Dialog for Settings */}
                        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
                            <DialogTitle>Settings</DialogTitle>
                            <DialogContent>
                                {/* Letterhead Image Selector */}
                                <Box sx={{ mt: 3 }}>
                                    <Typography>Choose Letterhead</Typography>
                                    <Select
                                        value={selectedLetterhead}
                                        onChange={(e) => setSelectedLetterhead(e.target.value)}
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        {letterheadOptions.map((option) => (
                                            <MenuItem key={option.path} value={option.path}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={option.path}
                                                        alt={option.label}
                                                        style={{ width: 200, height: 50, marginRight: 8 }}
                                                    />
                                                    {option.label}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                                <Grid2 container spacing={2}>
                                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="disponent-owners">Disponent Owners</InputLabel>
                                            <OutlinedInput
                                                id="disponent-owners"
                                                value={owners}
                                                onChange={(e) => setOwners(e.target.value)}
                                                label="Disponent Owners"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="vessel-name">Vessel</InputLabel>
                                            <OutlinedInput
                                                id="vessel-name"
                                                value={vesselName}
                                                onChange={(e) => setVesselName(e.target.value)}
                                                label="Vessel"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="port-of-loading">Port of Loading</InputLabel>
                                            <OutlinedInput
                                                id="port-of-loading"
                                                value={portLoading}
                                                onChange={(e) => setPortLoading(e.target.value)}
                                                label="Port of Loading"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="port-of-discharge">Port of Discharge</InputLabel>
                                            <OutlinedInput
                                                id="port-of-discharge"
                                                value={portDischarge}
                                                onChange={(e) => setPortDischarge(e.target.value)}
                                                label="Port of Discharge"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="shipper">Shipper</InputLabel>
                                            <OutlinedInput
                                                id="shipper"
                                                value={shipper}
                                                onChange={(e) => setShipper(e.target.value)}
                                                label="Shipper"
                                            />
                                        </FormControl>
                                    </Grid2>
                                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="requestor">Requestor</InputLabel>
                                            <OutlinedInput
                                                id="requestor"
                                                value={requestor}
                                                onChange={(e) => setRequestor(e.target.value)} // Change here
                                                label="Requestor"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined" margin="normal">
                                            <InputLabel htmlFor="requestor-address">Requestor Address</InputLabel>
                                            <OutlinedInput
                                                id="requestor-address"
                                                multiline
                                                rows={4}
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)} // Change here
                                                label="Requestor Address"
                                            />
                                        </FormControl>
                                    </Grid2>
                                </Grid2>
                            </DialogContent>
                        </Dialog>
                        <Divider sx={{ marginY: 2 }} />
                        <Typography variant="h6">Fields to Amend</Typography>
                        {Object.entries(comparison).map(([key, change]) => {
                            if (checkForChanged(change.significant_variance)) {
                                return (
                                    <Grid2 container spacing={2} key={key}>
                                        <Grid2 item size={{ xs: 6, sm: 3.5 }}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="field">Field Name</InputLabel>
                                                <OutlinedInput
                                                    id="field"
                                                    label="Field Name"
                                                    defaultValue={change.field}
                                                    disabled={!fieldStates[key]} // Disable if not enabled
                                                />
                                            </FormControl>
                                        </Grid2>
                                        <Grid2 item size={{ xs: 6, sm: 3.5 }}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="original-value">Original Value</InputLabel>
                                                <OutlinedInput
                                                    id="original-value"
                                                    label="Original Value"
                                                    defaultValue={doc1[toSnakeCase(change.field)]}
                                                    disabled={!fieldStates[key]} // Disable if not enabled
                                                />
                                            </FormControl>
                                        </Grid2>
                                        <Grid2 item size={{ xs: 6, sm: 3.5 }}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="updated-value">New Value</InputLabel>
                                                <OutlinedInput
                                                    id="updated-value"
                                                    label="New Value"
                                                    defaultValue={doc2[toSnakeCase(change.field)]}
                                                    disabled={!fieldStates[key]} // Disable if not enabled
                                                />
                                            </FormControl>
                                        </Grid2>
                                        <Grid2 item size={{ xs: 2, sm: 1.5 }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={fieldStates[key]} // Controlled switch state
                                                        onChange={() => handleSwitchChange(key)}
                                                    />
                                                }
                                                label="Include"
                                            />
                                        </Grid2>
                                    </Grid2>
                                );
                            }
                            return null;
                        })}
                        {comparison.hold_changes.map((hold, index) => {
                            const originalHold = doc1.hold_details.find(h => h.hold === hold.hold);
                            const updatedHold = doc2.hold_details.find(h => h.hold === hold.hold);
                            if (checkForHoldChange(hold)) {
                                return (
                                    <div key={hold.hold}>
                                        {renderHoldChanges(originalHold, updatedHold, hold, holdFieldStates, toggleFieldState, index)}
                                    </div>
                                );
                            }
                            return null;
                        })}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateAmendment}
                            style={{ marginTop: '16px' }}
                        >
                            Generate LOI for MR Amendment Preview
                        </Button>
                        <AmendmentModal
                            open={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            currentDate={new Date().toLocaleDateString()}
                            requestor={requestor}
                            address={address}
                            owners={owners}
                            vessel={vesselName}
                            portLoading={portLoading}
                            portDischarge={portDischarge}
                            shipper={shipper}
                            changes={changedFieldsWithTrueState}
                            holdChanges={changedHoldFieldsWithTrueState}
                            originalMR={doc1}
                            updatedMR={doc2}
                            letterheadImage={selectedLetterhead}
                            requestId={requestId}
                        />
                    </Box>
                ) : (
                    // JSX to render when fieldsToAmend is false
                    <Typography variant="h5" sx={{ color: 'red' }}>No LOI Amendment Required</Typography>
                )}
            </CardContent>
        </Card>
    );
};

const renderHoldChanges = (originalHold, updatedHold, holdChange, holdFieldStates, toggleFieldState, index) => {

    return (
        <Box>
            {["content_description", "quantity_description"].map(field => {
                const originalValue = originalHold[field];
                const udpatedValue = updatedHold[field];
                const changeValue = holdChange ? holdChange[`${field}_variance`] : null; // Get change info
                const fieldName = `Hold ${toTitleCase(field)}`;
                if (checkForChanged(changeValue)) {
                    return (
                        <Grid2 container spacing={2} key={field}>
                            <Grid2 item size={{ xs: 6, sm: 3.5 }}>
                                <FormControl fullWidth variant="outlined" margin="normal">
                                    <InputLabel htmlFor="field">Hold Field Name</InputLabel>
                                    <OutlinedInput
                                        id="field"
                                        label="Field Name"
                                        defaultValue={fieldName}
                                        disabled={!holdFieldStates[index][field]} // Disable if not enabled
                                    />
                                </FormControl>
                            </Grid2>
                            <Grid2 item size={{ xs: 6, sm: 3.5 }}>
                                <FormControl fullWidth variant="outlined" margin="normal">
                                    <InputLabel htmlFor="original-value">Original Value</InputLabel>
                                    <OutlinedInput
                                        readOnly
                                        id="original-value"
                                        label="Original Value"
                                        defaultValue={originalValue}
                                        disabled={!holdFieldStates[index][field]} // Disable if not enabled
                                    />
                                </FormControl>
                            </Grid2>
                            <Grid2 item size={{ xs: 6, sm: 3.5 }}>
                                <FormControl fullWidth variant="outlined" margin="normal">
                                    <InputLabel htmlFor="updated-value">New Value</InputLabel>
                                    <OutlinedInput
                                        readOnly
                                        id="updated-value"
                                        label="New Value"
                                        defaultValue={udpatedValue}
                                        disabled={!holdFieldStates[index][field]} // Disable if not enabled
                                    />
                                </FormControl>
                            </Grid2>
                            <Grid2 item size={{ xs: 2, sm: 1.5 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={holdFieldStates[index][field]} // Controlled switch state
                                            onChange={() => toggleFieldState(index, field)}
                                        />
                                    }
                                    label="Include"
                                />
                            </Grid2>
                        </Grid2>
                    );
                }
                return null;

            })}
        </Box>
    );
};

const AmendmentModal = ({ open, onClose, currentDate, owners, vessel, portLoading, portDischarge, shipper, requestor, address, changes, originalMR, updatedMR, holdChanges, letterheadImage, requestId }) => {
    AmendmentModal.propTypes = {
        open: PropTypes.bool.isRequired, 
        onClose: PropTypes.func,
        currentDate: PropTypes.string, 
        owners: PropTypes.string,
        vessel: PropTypes.string,
        portLoading: PropTypes.string, 
        portDischarge: PropTypes.string,
        shipper: PropTypes.string,
        requestor: PropTypes.string, 
        address: PropTypes.string,
        changes: PropTypes.object,
        originalMR: PropTypes.object,
        updatedMR: PropTypes.object,
        holdChanges: PropTypes.object,
        letterheadImage: PropTypes.string,
        requestId: PropTypes.string,
    };
    
    // State to store tracked changes
    const [trackedChanges, setTrackedChanges] = useState({});
    const { user } = useContext(UserContext);

    // Define a style for the modal box
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',  // To limit height and allow scroll
        bgcolor: 'background.paper',
        border: '1px solid #ccc',  // Light border
        borderRadius: '10px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',  // Softer shadow
        display: 'flex',
        flexDirection: 'column',  // Ensures sticky footer and scrolling content
        p: 4,
    };

    const contentStyle = {
        flex: 1,
        overflowY: 'auto',  // Allow vertical scroll
        padding: '16px',
        marginBottom: '60px',  // Space for sticky buttons
    };

    const strongStyle = {
        color: '#1976d2',  // Use a different shade (blue for example)
        fontWeight: 'bold',
    };

    // Function to copy modal content to clipboard
    const handleCopyToClipboard = () => {
        // Send track changes for evaluations
        sendTrackChangesToBackend();

        const element = document.getElementById("modal-content");
        const textToCopy = element.innerText || element.textContent; // Get only the text content
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                alert("Text copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    // Function to generate and save PDF
    const handleSaveAsPDF = () => {
        const element = document.getElementById("modal-content"); // Use the modal's container element

        const options = {
            margin: 1,
            filename: "MR_Amendment.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };

        // Send track changes for evaluations
        sendTrackChangesToBackend();

        // Save the PDF
        html2pdf().set(options).from(element).save();
    };

    // Function to add or update tracked changes with document IDs
    const trackChange = (key, field, newValue, documentId = null) => {
        setTrackedChanges((prevChanges) => {
            const originalValue = formData[key];
            // Only add to trackedChanges if the value is actually changed
            if (originalValue !== newValue) {
                return {
                    ...prevChanges,
                    [key]: { field, value: newValue, documentId },
                };
            } else {
                // Remove from trackedChanges if it reverts back to original
                // eslint-disable-next-line no-unused-vars
                const { [key]: _, ...remainingChanges } = prevChanges;
                return remainingChanges;
            }
        });
    };

    const prepareDataForAPI = (trackedChanges) => {
        const updatedFields = {};

        Object.values(trackedChanges).forEach(({ field, value, documentId }) => {
            updatedFields[field] = {
                value: value,
                documentId: documentId
            };
        });

        return updatedFields;
    };

    const sendTrackChangesToBackend = async () => {
        try {
            const result = await sendFieldFeedback(user.orgId, requestId, prepareDataForAPI(trackedChanges), "MR");
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    };

    // Memoize the functions so they are stable across renders
    const changesArray = useCallback((changes) => {
        return Object.entries(changes).map(([change]) => ({
            field: change.field,
            original: originalMR[toSnakeCase(change.field)] || "N/A",
            updated: updatedMR[toSnakeCase(change.field)] || "N/A",
        }));
    }, [originalMR, updatedMR]);

    const holdChangesArray = useCallback(() => {
        return holdChanges.map((change, index) => {
            return Object.values(change).map((field) => ({
                field: field.field,
                original: originalMR.hold_details[index]?.[field.field] || "N/A",
                updated: updatedMR.hold_details[index]?.[field.field] || "N/A",
            }));
        }).flat(); // Flatten the nested array to get a single-level array
    }, [holdChanges, originalMR, updatedMR]);

    const [formData, setFormData] = useState({
        currentDate: currentDate,
        vessel: vessel,
        owners: owners,
        portLoading: portLoading,
        portDischarge: portDischarge,
        shipper: shipper,
        requestorName: requestor,
        address: address,
        changes: changesArray(changes),
        holdChanges: holdChangesArray(holdChanges),
        letterhead: letterheadImage
    });

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            changes: changesArray(changes),
            holdChanges: holdChangesArray(holdChanges),
        }));
    }, [changes, changesArray, holdChanges, holdChangesArray, originalMR, updatedMR]);

    // Function to update changes or holdChanges
    const updateFormDataChanges = (type, index, field, newValue) => {
        setFormData((prevData) => {
            let updatedData;
            const fieldName = prevData[type][index]['field'];
            let documentId = null; // Document ID will be set based on the type of change

            if (type === 'changes') {
                const updatedChanges = [...prevData.changes];
                updatedChanges[index] = {
                    ...updatedChanges[index],
                    [field]: newValue,
                };
                updatedData = {
                    ...prevData,
                    changes: updatedChanges,
                };
            } else if (type === 'holdChanges') {
                const updatedHoldChanges = [...prevData.holdChanges];
                updatedHoldChanges[index] = {
                    ...updatedHoldChanges[index],
                    [field]: newValue,
                };
                updatedData = {
                    ...prevData,
                    holdChanges: updatedHoldChanges,
                };
            }

            // Determine document ID 
            if (field === 'original') {
                documentId = originalMR.doc_id;
            } else if (field === 'updated') {
                documentId = updatedMR.doc_id;
            }

            // Track changes in nested structures
            if (updatedData) {
                const originalValue = prevData[type][index][field];
                const changeKey = `${type}[${index}].${field}`;
                if (originalValue !== newValue) {
                    setTrackedChanges((prevChanges) => ({
                        ...prevChanges,
                        [changeKey]: { field: fieldName, value: newValue, documentId },
                    }));
                } else {
                    // Remove if the value reverts back to the original
                    setTrackedChanges((prevChanges) => {
                        // eslint-disable-next-line no-unused-vars
                        const { [changeKey]: _, ...remainingChanges } = prevChanges;
                        return remainingChanges;
                    });
                }
            }

            return updatedData;
        });
    };

    // Function to update formData for simple fields
    const updateFormData = (key, value) => {
        setFormData((prevData) => {
            // Track changes before updating formData (no document ID for simple fields)
            trackChange(key, key, value);
            return {
                ...prevData,
                [key]: value,
            };
        });
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Typography variant="h4">LOI for MR Amendment</Typography>

                <Box sx={contentStyle} id="modal-content">
                    <Typography variant="body1"><strong style={strongStyle}>{currentDate}</strong></Typography>
                    <Typography variant="body1">To:&nbsp;
                        <Typography
                            display={"inline"}
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                        >
                            <strong style={strongStyle}>
                                {formData.owners}
                            </strong>
                        </Typography>
                    </Typography>
                    <Typography variant="body1">Dear Sirs,</Typography>
                    <Typography variant="body1">
                        Vessel:&nbsp;
                        <Typography
                            display={"inline"}
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            onInput={(e) => updateFormData('vessel', e.currentTarget.textContent)}
                        >
                            <strong style={strongStyle}>
                                {formData.vessel}
                            </strong>
                        </Typography>
                    </Typography>

                    <Typography variant="body1">
                        Port of Loading:&nbsp;
                        <Typography
                            display={"inline"}
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            onInput={(e) => updateFormData('portLoading', e.currentTarget.textContent)}
                        >
                            <strong style={strongStyle}>
                                {formData.portLoading}
                            </strong>
                        </Typography>
                    </Typography>
                    <Typography variant="body1">
                        Port of Discharge:&nbsp;
                        <Typography
                            display={"inline"}
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            onInput={(e) => updateFormData('portDischarge', e.currentTarget.textContent)}
                        >
                            <strong style={strongStyle}>
                                {formData.portDischarge}
                            </strong>
                        </Typography>
                    </Typography>
                    <Typography variant="body1">
                        Shipper:&nbsp;
                        <Typography
                            display={"inline"}
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                            onInput={(e) => updateFormData('shipper', e.currentTarget.textContent)}
                        >
                            <strong style={strongStyle}>
                                {formData.shipper}
                            </strong>
                        </Typography>
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        We hereby request you to amend the Mates Receipt as follows:
                    </Typography>
                    <Divider sx={{ marginY: 2 }} />

                    <List sx={{ listStyle: "decimal", paddingLeft: 2 }}>
                        {formData.changes.map((change, index) => (
                            <ListItem key={index} sx={{ display: "list-item" }}>
                                Change&nbsp;
                                <Typography
                                    display={"inline"}
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={(e) => updateFormDataChanges('changes', index, 'field', e.currentTarget.textContent)}
                                >
                                    <strong style={strongStyle}>
                                        {toTitleCase(change.field)}
                                    </strong>
                                </Typography>
                                &nbsp;from&nbsp;
                                <Typography
                                    display={"inline"}
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={(e) => updateFormDataChanges('changes', index, 'original', e.currentTarget.textContent)}
                                >
                                    <strong style={strongStyle}>
                                        {change.original}
                                    </strong>
                                </Typography>
                                &nbsp;to&nbsp;
                                <Typography
                                    display={"inline"}
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={(e) => updateFormDataChanges('changes', index, 'updated', e.currentTarget.textContent)}
                                >
                                    <strong style={strongStyle}>
                                        {change.updated}
                                    </strong>
                                </Typography>
                            </ListItem>
                        ))}
                        {formData.holdChanges.map((change, index) => (
                            <ListItem key={index} sx={{ display: "list-item" }}>
                                Change&nbsp;
                                <Typography
                                    display={"inline"}
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={(e) => updateFormDataChanges('holdChanges', index, 'field', e.currentTarget.textContent)}
                                >
                                    <strong style={strongStyle}>
                                        {toTitleCase(change.field)}
                                    </strong>
                                </Typography>
                                &nbsp;from&nbsp;
                                <Typography
                                    display={"inline"}
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={(e) => updateFormDataChanges('holdChanges', index, 'original', e.currentTarget.textContent)}
                                >
                                    <strong style={strongStyle}>
                                        {change.original}
                                    </strong>
                                </Typography>
                                &nbsp;to&nbsp;
                                <Typography
                                    display={"inline"}
                                    suppressContentEditableWarning={true}
                                    contentEditable={true}
                                    onInput={(e) => updateFormDataChanges('holdChanges', index, 'updated', e.currentTarget.textContent)}
                                >
                                    <strong style={strongStyle}>
                                        {change.updated}
                                    </strong>
                                </Typography>
                            </ListItem>
                        ))}
                    </List>

                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        In consideration of your complying with our above request, we hereby agree as follows:
                    </Typography>

                    <List sx={{ listStyle: "decimal", paddingLeft: 2 }}>
                        <ListItem sx={{ display: "list-item" }}>
                            <ListItemText primary="To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of making the requested amendment(s)." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <ListItemText primary="In the event of any proceedings being commenced against you or any of your servants or agents in connection with making the requested amendment(s) as aforesaid, to provide you or them on demand with sufficient funds to defend the same." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <ListItemText primary="If, in connection with making the requested amendment(s) as aforesaid, the ship or any other ship or property in the same or associated ownership, management or control should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the vessel (whether by virtue of a caveat being entered on the ship's registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not this may be justified." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <ListItemText primary="The liability of each and every person under this Indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this Indemnity." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            <ListItemText primary="This Indemnity shall be governed by and construed in accordance with English law and each and every person liable under this Indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England." />
                        </ListItem>
                    </List>

                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        <strong style={strongStyle}>{requestor}</strong>
                    </Typography>
                    <Typography variant="body1"><strong style={strongStyle}>{address}</strong></Typography>
                    <Typography variant="body1">Yours faithfully,</Typography>
                    <Typography variant="body1">For and on behalf of</Typography>
                    <Typography variant="body1"><strong style={strongStyle}>{requestor}</strong></Typography>
                    <Typography variant="body1">The Requestor</Typography>
                </Box>
                <Box>
                    <Button onClick={handleSaveAsPDF}>Save as PDF</Button>
                    <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
                    <Button onClick={() => { sendTrackChangesToBackend(); handleSaveAsWordMRLOI(formData) }}>Save as Word</Button>
                    <Button onClick={onClose}>Close</Button>
                </Box>
            </Box>
        </Modal>
    );
};


export default MatesReceiptsResult;
