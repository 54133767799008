import React, { useState, useContext } from 'react';
import { Button, TextField, Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { approvedUsers } from '../../config/loginDetails'; // Import user data
import { UserContext } from '../../context/UserContext';

const LoginScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);  // Toggle for password visibility
    const [error, setError] = useState('');  // State for error message
    const { updateUser } = useContext(UserContext); // Accessing the context

    // Function to check credentials
    const handleLogin = () => {
        const validUser = approvedUsers.find(
            (user) => user.username === username && user.password === password
        );

        if (validUser) {
            const userData = {
                username: validUser.username,
                orgId: validUser.organisation,
                fullName: validUser.fullName? validUser.fullName : "John Doe",
                isAdmin: validUser.isAdmin,
            };
            updateUser(userData); // Update the context with user data
            setError('');
        } else {
            setError("Username or password is incorrect.");
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handle Enter key press for login
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <Box textAlign="center" p={4} borderRadius={2}>
                <h2>Login to Shipster Tools</h2>

                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    margin="normal"
                />

                <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}  // Toggle type based on showPassword state
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    onKeyDown={handleKeyDown}  // Trigger login on Enter key press
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Display error message if credentials are incorrect */}
                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    sx={{ mt: 2 }}
                >
                    Login
                </Button>
            </Box>
        </Box>
    );
};

export default LoginScreen;
