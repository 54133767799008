import React, { useState, useContext } from 'react';
import ThumbsUpIcon from '@mui/icons-material/ThumbUp';
import ThumbsDownIcon from '@mui/icons-material/ThumbDown';
import {
    Box,
    Button,
    Typography,
    TextField,
} from "@mui/material";
import { sendUserFeedback } from "../../services/feedbackService";
import { UserContext } from '../../context/UserContext';
import PropTypes from 'prop-types';

const Feedback = ({ requestId, type }) => {
    Feedback.propTypes = {
        type: PropTypes.string.isRequired,
        requestId: PropTypes.string.isRequired,
    };

    const [feedback, setFeedback] = useState(null); // State to store feedback
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); // State to toggle feedback form
    const [additionalInfo, setAdditionalInfo] = useState(''); // State for additional info input
    const { user } = useContext(UserContext);

    const handleThumbsDownClick = () => {
        setShowFeedbackForm(true);
        setFeedback('thumbsDown');
    };

    const handleThumbsUpClick = () => {
        setFeedback('thumbsUp');
        handleSendFeedback(true); // Send feedback directly on thumbs up click
        setShowFeedbackForm(false); // Hide feedback form if thumbs up is clicked
    };

    const handleSendFeedback = async (accepted) => {
        try {
            const result = await sendUserFeedback(user.orgId, requestId, accepted, additionalInfo, type);
            console.log(result);
        } catch (error) {
            console.log(error);
        }
        setShowFeedbackForm(false);
        setAdditionalInfo('');
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography variant="body1" sx={{ mr: 2 }}>Are you satisfied with the results?</Typography>
            {!feedback && (
                <>
                    <Button onClick={handleThumbsUpClick} sx={{ mr: 1, background: 'transparent' }}>
                        <ThumbsUpIcon style={{ color: 'grey' }}/>
                    </Button>
                    <Button onClick={handleThumbsDownClick} sx={{ background: 'transparent'}}>
                        <ThumbsDownIcon style={{ color: 'grey' }}/>
                    </Button>
                </>
            )}
            {feedback === 'thumbsDown' && showFeedbackForm && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    <TextField
                        variant="outlined"
                        placeholder="Additional feedback"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        multiline // Make it a multiline input
                        rows={4} // Set the number of visible rows
                        sx={{ mr: 1, width: '300px' }} // Adjust width as needed
                    />
                    <Button variant="contained" onClick={() => handleSendFeedback(false)}>
                        Send
                    </Button>
                </Box>
            )}
            {feedback === 'thumbsDown' && !showFeedbackForm && (
                <>
                    <Button disabled sx={{ mr: 1 }}>
                        <ThumbsDownIcon />
                    </Button>
                    <Typography variant="body2" sx={{ ml: 2 }}>Thank you for your feedback!</Typography>
                </>
            )}
            {feedback === 'thumbsUp' && (
                <>
                    <Button disabled sx={{ mr: 1 }}>
                        <ThumbsUpIcon />
                    </Button>
                </>
            )}
        </Box>
    );
};

export default Feedback;
