import React, { useState, useContext, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Typography,
    FormControlLabel,
    Collapse,
    Grid2,
    IconButton,
    Alert,
    CircularProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Feedback from "../../components/common/Feedback"
import PDFViewer from "../../components/common/PDFViewer"
import { UserContext } from '../../context/UserContext';
import { compareSOFFiles } from "../../services/sofService";
import SOFResult from "./SOFResult"
import { getNumberOfPages } from "../../helpers"
import PageSelector from "../../components/common/PageSelector"
import ToolHeader from "../../components/common/ToolHeader"
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar"

const SOFComparison = () => {
    const [agentFile, setAgentFile] = useState(null);
    const [masterFile, setMasterFile] = useState(null);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [useCodeOCR, setUseCodeOCR] = useState(true);
    const [useGPT4VisionOcr, setUseGPT4VisionOCR] = useState(true);
    const [useDetailCache, setUseDetailCache] = useState(true);
    const [useComparisonCache, setUseComparisonCache] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const { user } = useContext(UserContext);
    const [agentPageCount, setAgentPageCount] = useState(1);
    const [agentStartPage, setAgentStartPage] = useState(1);
    const [agentEndPage, setAgentEndPage] = useState(agentPageCount ? agentPageCount : 1);
    const [masterPageCount, setMasterPageCount] = useState(1);
    const [masterStartPage, setMasterStartPage] = useState(1);
    const [masterEndPage, setMasterEndPage] = useState(masterPageCount ? masterPageCount : 1);
    const steps = ["Upload & Compare", "Results"];
    const [currentStep, setCurrentStep] = useState(0);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    useEffect(() => {
        if (agentFile) {
            getNumberOfPages(agentFile).then((pageCount) => { setAgentPageCount(pageCount); setAgentEndPage(pageCount) });
        }
    }, [agentFile]);

    useEffect(() => {
        if (masterFile) {
            getNumberOfPages(masterFile).then((pageCount) => { setMasterPageCount(pageCount); setMasterEndPage(pageCount) });
        }
    }, [masterFile]);

    const handleAdvancedOptionsClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const handleDropSection1 = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setMasterFile(acceptedFiles[0]);
        }
    };

    const handleDropSection2 = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setAgentFile(acceptedFiles[0]);
        }
    };

    const handleCompare = async () => {
        if (masterFile.length === 0 || !agentFile) {
            alert("Please upload file(s) in both sections before comparing.");
            return;
        }

        let useOCR = useCodeOCR
        if (user.orgId === "christiania") {
            useOCR = false;
        } else if (user.orgId === "navigator") {
            useOCR = true;
        }

        console.log("Comparing SOF files...", {
            masterFile,
            agentFile,
            options: {
                useOCR: useOCR,
                useVision: useGPT4VisionOcr,
                useComparisonCache,
                useDetailCache,
            },
        });

        setLoading(true);
        setError(null); // Clear any previous errors
        setResult(null); // Clear previous result

        try {
            const response = await compareSOFFiles(agentFile, masterFile, {
                useOCR: useOCR,
                useVision: useGPT4VisionOcr,
                useDetailCache,
                useComparisonCache,
                agentPageRanges: [[agentStartPage, agentEndPage]],
                masterPageRanges: [[masterStartPage, masterEndPage]],
                organisationId: user.orgId, // you can set a default organisation ID if needed
            });
            setResult(response); // Store the result
            console.log(response);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const {
        getRootProps: getRootPropsSection1,
        getInputProps: getInputPropsSection1,
    } = useDropzone({
        onDrop: handleDropSection1,
        accept: {
            "application/pdf": [".pdf"]
        }
    });

    const {
        getRootProps: getRootPropsSection2,
        getInputProps: getInputPropsSection2,
    } = useDropzone({
        onDrop: handleDropSection2,
        accept: {
            "application/pdf": [".pdf"]
        }
    });

    return (
        <Box p={3}>
            <ToolHeader title={"Statement of Fact"} subtext={"Compare Agent’s and Master’s Statements of Fact"}></ToolHeader>
            <ToolStepProgressBar
                steps={steps}
                currentStep={currentStep}
                onStepChange={handleStepChange}
            />

            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            {/* Grid Layout for Upload Sections */}
            <Grid2 container spacing={2} sx={{ mt: 3 }}>
                <Grid2 size={6} key={2}>
                    <Box sx={{ border: "1px solid gray", p: 3, borderRadius: 2 }}>
                        <Typography>Upload Agent Statement of Fact PDF</Typography>
                        <Box
                            {...getRootPropsSection2()}
                            sx={{
                                border: "2px dashed #ccc",
                                padding: 3,
                                textAlign: "center",
                                mt: 2,
                                borderRadius: 2,
                            }}
                        >
                            <input {...getInputPropsSection2()} />
                            <CloudUploadIcon sx={{ fontSize: "3rem" }} />
                            <Typography>Drag and drop files here or</Typography>
                            <Button variant="contained" sx={{ mt: 2 }}>
                                Browse Files
                            </Button>
                        </Box>
                        {/* Display Uploaded Files */}
                        {agentFile && (
                            <Box sx={{ mt: 2 }}>
                                <Typography>
                                    {agentFile.name}
                                    <IconButton onClick={() => setAgentFile(null)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {/* PDF Viewer Section */}
                    {agentFile && (
                        <PDFViewer file={agentFile}></PDFViewer>
                    )}
                </Grid2>
                <Grid2 size={6} key={1}>
                    <Box sx={{ border: "1px solid gray", p: 3, borderRadius: 2 }}>
                        <Typography>Upload Master Statement of Fact PDF</Typography>
                        <Box
                            {...getRootPropsSection1()}
                            sx={{
                                border: "2px dashed #ccc",
                                padding: 3,
                                textAlign: "center",
                                mt: 2,
                                borderRadius: 2,
                            }}
                        >
                            <input {...getInputPropsSection1()} />
                            <CloudUploadIcon sx={{ fontSize: "3rem" }} />
                            <Typography>Drag and drop files here or</Typography>
                            <Button variant="contained" sx={{ mt: 2 }}>
                                Browse Files
                            </Button>
                        </Box>
                        {/* Display Uploaded Files */}
                        {masterFile && (
                            <Box sx={{ mt: 2 }}>
                                <Typography>
                                    {masterFile.name}
                                    <IconButton onClick={() => setMasterFile(null)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {/* PDF Viewer Section */}
                    {masterFile && (
                        <PDFViewer file={masterFile}></PDFViewer>
                    )}
                </Grid2>
            </Grid2>

            <Typography variant="h6" sx={{ mt: 2 }}>Page Selection</Typography>
            <Grid2 container spacing={2}>
                <PageSelector
                    pageCount={agentPageCount}
                    startPage={agentStartPage}
                    setStartPage={setAgentStartPage}
                    endPage={agentEndPage}
                    setEndPage={setAgentEndPage}
                />
                <PageSelector
                    pageCount={masterPageCount}
                    startPage={masterStartPage}
                    setStartPage={setMasterStartPage}
                    endPage={masterEndPage}
                    setEndPage={setMasterEndPage}
                />
            </Grid2>

            {user.isAdmin && (
                <>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                    >
                        {showAdvancedOptions
                            ? "Hide Advanced Options"
                            : "Show Advanced Options"}
                    </Button>
                    <Collapse in={showAdvancedOptions}>
                        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useCodeOCR}
                                        onChange={(e) => setUseCodeOCR(e.target.checked)}
                                    />
                                }
                                label="Use Tesseract OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useGPT4VisionOcr}
                                        onChange={(e) => setUseGPT4VisionOCR(e.target.checked)}
                                    />
                                }
                                label="GPT Vision OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useDetailCache}
                                        onChange={(e) => setUseDetailCache(e.target.checked)}
                                    />
                                }
                                label="Use Detail Cache"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useComparisonCache}
                                        onChange={(e) => setUseComparisonCache(e.target.checked)}
                                    />
                                }
                                label="Use Comparison Cache"
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {/* Compare Button */}
            <Button
                onClick={handleCompare}
                variant="contained"
                sx={{ mt: 3 }}
                disabled={!masterFile || !agentFile}
            >
                Compare SOF
            </Button>


            {result && (
                <Feedback requestId={result.request_id} type={"SOF"}></Feedback>
            )}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <SOFResult result={result}></SOFResult>
            )}

        </Box>
    );
};

export default SOFComparison;
