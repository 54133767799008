import React from 'react';
import { Box, Typography, Paper, Tooltip, IconButton, } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';

const MatchingDocumentPreview = ({ doc1, doc2, title, disablePaper }) => {
    MatchingDocumentPreview.propTypes = {
        doc1: PropTypes.object,
        doc2: PropTypes.object,
        title: PropTypes.string,
        disablePaper: PropTypes.bool,
    };

    const highlightIfMatch = (mrValue, blValue, important) => {
        // Helper function to normalize text by removing whitespace and common punctuation
        const normalize = (text) => text.replace(/\s+|[.,/]/g, '').toUpperCase();

        // Normalize both values for easier comparison
        const normalizedMrValue = normalize(mrValue);
        const normalizedBlValue = normalize(blValue);

        // Check if values match exactly after normalization
        const isExactMatch = normalizedMrValue === normalizedBlValue;

        // Check if one value is contained within the other
        const isPartialMatch =
            normalizedMrValue.includes(normalizedBlValue) ||
            normalizedBlValue.includes(normalizedMrValue);

        // Apply green highlighting if there is an exact or partial match
        if (important) {
            return isExactMatch || isPartialMatch ? { color: 'green', fontWeight: 'bold' } : { color: 'red', fontWeight: 'bold' };
        }
        else {
            return isExactMatch || isPartialMatch ? { color: 'green', fontWeight: 'bold' } : {};
        }
    };

    return (
        <Paper elevation={disablePaper ? 0 : 3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Short Name</Typography>
                <Typography style={highlightIfMatch(doc1?.short_name, doc2?.short_name)}>
                    {doc1?.short_name}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Date</Typography>
                <Typography style={highlightIfMatch(doc1?.date, doc2?.date)}>
                    {doc1?.date}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Vessel</Typography>
                <Typography style={highlightIfMatch(doc1?.vessel, doc2?.vessel)}>
                    {doc1?.vessel}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Port of Loading</Typography>
                <Typography style={highlightIfMatch(doc1?.port_of_loading, doc2?.port_of_loading)}>
                    {doc1?.port_of_loading}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Port of Discharge</Typography>
                <Typography style={highlightIfMatch(doc1?.port_of_discharge, doc2?.port_of_discharge)}>
                    {doc1?.port_of_discharge}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Shipper</Typography>
                <Typography style={highlightIfMatch(doc1?.shipper, doc2?.shipper)}>
                    {doc1?.shipper}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Shipper Reference
                    <Tooltip title={"Shipper Reference was part of the matching Criteria with weight 1.0"}>
                        <IconButton sx={{ color: 'gray', opacity: 0.5}}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <Typography style={highlightIfMatch(doc1?.shipper_reference, doc2?.shipper_reference, true)}>
                    {doc1?.shipper_reference}
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2">Shipping Marks
                <Tooltip title={"Shipper Marks was part of the matching Criteria with weight 1.0"}>
                        <IconButton sx={{ color: 'gray', opacity: 0.5}}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <Typography style={highlightIfMatch(doc1?.shipping_marks, doc2?.shipping_marks, true)}>
                    {doc1?.shipping_marks}
                </Typography>
            </Box>
            {/* Hold Details */}
            <Box sx={{ mb: 1 }}>
                <Typography variant="h6" gutterBottom>Hold Details</Typography>
                {doc1?.hold_details.map((hold, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Hold ID: {hold.hold_id}
                        </Typography>
                        <Typography style={highlightIfMatch(hold.content_description, doc2?.hold_details[index]?.content_description)}>
                            {hold.content_description}
                        </Typography>
                        <Typography style={highlightIfMatch(hold.quantity_description, doc2?.hold_details[index]?.quantity_description)}>
                            {hold.quantity_description}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

export default MatchingDocumentPreview;
