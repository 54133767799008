import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Collapse,
    Grid2,
    Alert,
    CircularProgress,
} from "@mui/material";
import { compareCargoPdfs } from "../../services/cargoService";
import Feedback from "../../components/common/Feedback"
import CargoResult from "./CargoResult"
import { UserContext } from "../../context/UserContext";
import DocumentUploader from "../../components/common/DocumentUploader"
import ToolHeader from "../../components/common/ToolHeader"
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar"

const CargoComparison = () => {
    const [billOfLadings, setBillOfLadings] = useState([]);
    const [voyageManifests, setVoyageManifests] = useState([]);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [useOCR, setUseOCR] = useState(true);
    const [useVision, setUseVision] = useState(true);
    const [useContentCache, setUseContentCache] = useState(true);
    const [useCargoCache, setUseCargoCache] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const { user } = useContext(UserContext);
    const steps = ["Upload & Compare", "Results"];
    const [currentStep, setCurrentStep] = useState(0);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    const handleAdvancedOptionsClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const handleCompare = async () => {
        if (voyageManifests.length === 0 || billOfLadings.length === 0) {
            alert("Please upload file(s) in both sections before comparing.");
            return;
        }
        console.log("Comparing cargo...", {
            voyageManifests,
            billOfLadings,
            options: {
                useOCR,
                useVision,
                useContentCache,
                useCargoCache,
            },
        });

        setLoading(true);
        setError(null); // Clear any previous errors
        setResult(null); // Clear previous result

        try {
            const response = await compareCargoPdfs(billOfLadings, voyageManifests, {
                useOCR,
                useVision,
                useContentCache,
                useCargoCache,
                organisationId: user.orgId, // you can set a default organisation ID if needed
            });
            setResult(response); // Store the result
            console.log(response);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            <ToolHeader title={"Voyage Manifest -> draft B/L"} subtext={"Compare cargo from Voyage Manifest with draft Bill of Lading"}></ToolHeader>
            <ToolStepProgressBar
                steps={steps}
                currentStep={currentStep}
                onStepChange={handleStepChange}
            />
            
            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            {/* Grid Layout for Upload Sections */}
            <Grid2 container spacing={2} sx={{ mt: 3 }}>
                {/* Section 1 for File Upload */}
                <Grid2 size={6} key={1}>
                    <DocumentUploader files={voyageManifests} setFiles={setVoyageManifests} title={"Upload one or more Voyage Manifest PDF(s)"} setResult={setResult}></DocumentUploader>
                </Grid2>

                {/* Section 2 for File Upload */}
                <Grid2 size={6} key={2}>
                    <DocumentUploader files={billOfLadings} setFiles={setBillOfLadings} title={"Upload Bill of Lading PDF"} setResult={setResult}></DocumentUploader>
                </Grid2>
            </Grid2>

            {user.isAdmin && (
                <>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                    >
                        {showAdvancedOptions
                            ? "Hide Advanced Options"
                            : "Show Advanced Options"}
                    </Button>
                    <Collapse in={showAdvancedOptions}>
                        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useOCR}
                                        onChange={(e) => setUseOCR(e.target.checked)}
                                    />
                                }
                                label="Use OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useVision}
                                        onChange={(e) => setUseVision(e.target.checked)}
                                    />
                                }
                                label="Use Vision"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useContentCache}
                                        onChange={(e) => setUseContentCache(e.target.checked)}
                                    />
                                }
                                label="Use Content Cache"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useCargoCache}
                                        onChange={(e) => setUseCargoCache(e.target.checked)}
                                    />
                                }
                                label="Use Cargo Cache"
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {/* Compare Button */}
            <Button
                onClick={handleCompare}
                variant="contained"
                sx={{ mt: 3 }}
                disabled={voyageManifests.length === 0 || billOfLadings.length === 0}
            >
                Compare Cargo
            </Button>

            {result && (
                <Feedback requestId={result.request_id} type={"Cargo"}></Feedback>
            )}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <CargoResult response={result}></CargoResult>
            )}

        </Box>
    );
};

export default CargoComparison;
