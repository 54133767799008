import axios from "axios";
import { API_URL } from "./config";

export const loi2loi = async (
    file, ownersManagers, requesterFullName, requesterDesignation, requesterParty,
    options = {
        useOCR: true,
        useVision: true,
        useContentCache: true,
        useLOICache: true,
        organisationId: "", // you can set a default organisation ID if needed
    }
) => {
    const formData = new FormData();
    formData.append('original_loi', file);  // The file object (PDF)
    formData.append("ocr", options.useOCR);
    formData.append("vision", options.useVision);
    formData.append("use_content_cache", options.useContentCache);
    formData.append("use_loi_cache", options.useLOICache);
    formData.append('owners_managers', ownersManagers); 
    formData.append('requestor_full_name', requesterFullName);  
    formData.append('requestor_designation', requesterDesignation); 
    formData.append('requestor_party', requesterParty); 
    formData.append("organisation_id", options.organisationId);

    try {
        const response = await axios.post(`${API_URL}/loi2loi/loi2loi`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
    } catch (error) {
        console.error('Error extracting LOI:', error);
        throw error;
    }
};
