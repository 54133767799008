// src/styles/index.js

export const globalStyles = {
    body: {
        backgroundColor: "#17191E", // Default Dark Mode background color
        backgroundImage: "url('/assets/Topographic3.svg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        color: "#ffffff", // Default Dark Mode text color
        margin: 0,
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
      },
      '[data-theme="light"] body': {
        backgroundColor: "#ffffff", // Light Mode background color
        color: "#000000", // Light Mode text color
      },
  };
  