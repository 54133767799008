import React, { useState, useEffect } from 'react';
import {
    Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, IconButton, Collapse, useTheme, Typography
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import GradientAutoAwesomeIcon from '../../components/icons/GradientAutoAwesomeIcon'
import PropTypes from 'prop-types';
import VarianceSummary from '../../components/comparison/VarianceSummary'
import ComparisonTable from '../../components/comparison/ComparisonTable';
import DiscrepanciesList from '../../components/comparison/DiscrepanciesList'
import * as XLSX from "xlsx";

const calculateDifference = (value1, value2) => {
    if (!value1) return value2;
    if (!value2) return value1;
    return Number(Math.abs(value1) - Math.abs(value2)).toFixed(2);
};

const borderStyle = {
    borderRight: '2px solid #2D6BCB',
};

const borderBottomStyle = {
    borderBottom: '2px solid #2D6BCB',
};

const allBorderStyle = {
    borderBottom: '2px solid #2D6BCB',
    borderRight: '2px solid #2D6BCB',
};

const HireStatementResult = ({ comparisonResult }) => {
    HireStatementResult.propTypes = {
        comparisonResult: PropTypes.object.isRequired,
    };

    const [hireComparisonResult, setHireComparisonResult] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        setHireComparisonResult(comparisonResult);

    }, [comparisonResult]);

    if (!hireComparisonResult) return null;

    const { comparison_result } = hireComparisonResult;

    const handleCopy = () => {
        const rows = gatherAllRows(filteredRows); // A function to collect all rows data
        const tableData = rows.map(row => row.join("\t")).join("\n");
        navigator.clipboard.writeText(tableData).then(() => {
            console.log("Table copied to clipboard.");
        }).catch(err => {
            console.error("Failed to copy table to clipboard", err);
        });
    };

    const handleSavePdf = () => {
        console.log('Save as PDF functionality.');
    };

    const handleSaveExcel = () => {
        const rows = gatherAllRows(filteredRows); // A function to collect all rows data
        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, "Table Export");
        XLSX.writeFile(workbook, "table_export.xlsx");
    };

    // Function to gather rows (generic version)
    const gatherAllRows = (rows) => {
        const allRows = [];
        rows.forEach((row) => {
            const mainRow = [
                row.title,
                row.category1Total?.amount_float || row.category1Total?.calculated_amount || "-",
                "",
                row.category2Total?.amount_float || row.category2Total?.calculated_amount || "-",
                "",
                calculateDifference(
                    row.category1Total?.amount_float || row.category1Total?.calculated_amount,
                    row.category2Total?.amount_float || row.category2Total?.calculated_amount
                ),
            ];
            allRows.push(mainRow);

            // Add sub-items
            const subItems = Array.from({ length: Math.max(row.category1.length, row.category2.length) }).map((_, idx) => ([
                "",
                row.category1[idx]?.amount_float || row.category1[idx]?.calculated_amount || "-",
                row.category1[idx]?.field_name || row.category1[idx]?.fuel_name || "-",
                row.category2[idx]?.amount_float || row.category2[idx]?.calculated_amount || "-",
                row.category2[idx]?.field_name || row.category2[idx]?.fuel_name || "-",
                "",
            ]));
            allRows.push(...subItems);
        });
        return allRows;
    };

    const { hire_statement_1, hire_statement_2 } = comparison_result;
    const discrepancies = [
        {
            title: 'Gross Hire Total',
            difference: comparison_result.comparison_result.gross_hire_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.gross_hire_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.gross_hire_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.gross_hire_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.gross_hire_total.amount_float > 0 ? hire_statement_1.gross_hire_total.amount_float : hire_statement_1.gross_hire_total.calculated_amount,
                hire_statement_2.gross_hire_total.amount_float > 0 ? hire_statement_2.gross_hire_total.amount_float : hire_statement_2.gross_hire_total.calculated_amount
            ),
        },
        {
            title: "Off Hire Total",
            difference: comparison_result.comparison_result.off_hire_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.off_hire_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.off_hire_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.off_hire_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.total_off_hire.amount_float > 0 ? hire_statement_1.total_off_hire.amount_float : hire_statement_1.total_off_hire.calculated_amount,
                hire_statement_2.total_off_hire.amount_float > 0 ? hire_statement_2.total_off_hire.amount_float : hire_statement_2.total_off_hire.calculated_amount
            ),
        },
        {
            title: "Net Hire Total",
            difference: comparison_result.comparison_result.net_hire_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.net_hire_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.net_hire_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.net_hire_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.total_net_hire.amount_float > 0 ? hire_statement_1.total_net_hire.amount_float : hire_statement_1.total_net_hire.calculated_amount,
                hire_statement_2.total_net_hire.amount_float > 0 ? hire_statement_2.total_net_hire.amount_float : hire_statement_2.total_net_hire.calculated_amount
            ),
        },
        {
            title: "Bunker Value on Delivery",
            difference: comparison_result.comparison_result.bunker_value_on_delivery_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.bunker_value_on_delivery_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.bunker_value_on_delivery_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.bunker_value_on_delivery_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.bunker_value_on_delivery_total.amount_float > 0 ? hire_statement_1.bunker_value_on_delivery_total.amount_float : hire_statement_1.bunker_value_on_delivery_total.calculated_amount,
                hire_statement_2.bunker_value_on_delivery_total.amount_float > 0 ? hire_statement_2.bunker_value_on_delivery_total.amount_float : hire_statement_2.bunker_value_on_delivery_total.calculated_amount
            ),
        },
        {
            title: "Bunker Value on Completion",
            difference: comparison_result.comparison_result.bunker_value_on_completion_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.bunker_value_on_completion_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.bunker_value_on_completion_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.bunker_value_on_completion_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.bunker_value_on_completion_total.amount_float > 0 ? hire_statement_1.bunker_value_on_completion_total.amount_float : hire_statement_1.bunker_value_on_completion_total.calculated_amount,
                hire_statement_2.bunker_value_on_completion_total.amount_float > 0 ? hire_statement_2.bunker_value_on_completion_total.amount_float : hire_statement_2.bunker_value_on_completion_total.calculated_amount
            ),
        },
        {
            title: "Owners Expenses Total",
            difference: comparison_result.comparison_result.owners_expenses_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.owners_expenses_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.owners_expenses_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.owners_expenses_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.owners_expenses_total.amount_float > 0 ? hire_statement_1.owners_expenses_total.amount_float : hire_statement_1.owners_expenses_total.calculated_amount,
                hire_statement_2.owners_expenses_total.amount_float > 0 ? hire_statement_2.owners_expenses_total.amount_float : hire_statement_2.owners_expenses_total.calculated_amount
            ),
        },
        {
            title: "Charterers Expenses Total",
            difference: comparison_result.comparison_result.charterers_expenses_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.charterers_expenses_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.charterers_expenses_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.charterers_expenses_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.charterers_expenses_total.amount_float > 0 ? hire_statement_1.charterers_expenses_total.amount_float : hire_statement_1.charterers_expenses_total.calculated_amount,
                hire_statement_2.charterers_expenses_total.amount_float > 0 ? hire_statement_2.charterers_expenses_total.amount_float : hire_statement_2.charterers_expenses_total.calculated_amount
            ),
        },
        {
            title: "Other Adjustments Total",
            difference: comparison_result.comparison_result.other_adjustments_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.other_adjustments_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.other_adjustments_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.other_adjustments_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.other_adjustments_total.amount_float > 0 ? hire_statement_1.other_adjustments_total.amount_float : hire_statement_1.other_adjustments_total.calculated_amount,
                hire_statement_2.other_adjustments_total.amount_float > 0 ? hire_statement_2.other_adjustments_total.amount_float : hire_statement_2.other_adjustments_total.calculated_amount
            ),
        },
        {
            title: "Payments So Far Total",
            difference: comparison_result.comparison_result.payments_so_far_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.payments_so_far_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.payments_so_far_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.payments_so_far_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.payments_so_far_total.amount_float > 0 ? hire_statement_1.payments_so_far_total.amount_float : hire_statement_1.payments_so_far_total.calculated_amount,
                hire_statement_2.payments_so_far_total.amount_float > 0 ? hire_statement_2.payments_so_far_total.amount_float : hire_statement_2.payments_so_far_total.calculated_amount
            ),
        },
        {
            title: "Balance Due",
            difference: comparison_result.comparison_result.balance_due_discrepancies[0]?.discrepancy,
            possibleCause: comparison_result.comparison_result.balance_due_discrepancies[0]?.discrepancy_cause,
            suggestedAction: comparison_result.comparison_result.balance_due_discrepancies[0]?.suggested_analyst_action,
            severity: comparison_result.comparison_result.balance_due_discrepancies[0]?.severity,
            discrepancy: calculateDifference(
                hire_statement_1.balance_due.amount_float > 0 ? hire_statement_1.balance_due.amount_float : hire_statement_1.balance_due.calculated_amount,
                hire_statement_2.balance_due.amount_float > 0 ? hire_statement_2.balance_due.amount_float : hire_statement_2.balance_due.calculated_amount
            ),
        }
    ];

    return (
        <Box>
            <VarianceSummary summary={comparison_result.comparison_result.summary} />
            <ComparisonTable
                onCopy={handleCopy}
                onSavePdf={handleSavePdf}
                onSaveExcel={handleSaveExcel}
                filterOptions={[
                    { value: 'all', label: 'Show All' },
                    { value: 'discrepancies', label: 'Show Only Discrepancies' },
                    { value: 'missing', label: 'Show Only Missing' },
                ]}
            >
                <HireStatementTable comparisonResult={comparison_result} filter={'all'} filteredRows={filteredRows} setFilteredRows={setFilteredRows} />
            </ComparisonTable>
            <DiscrepanciesList discrepancies={discrepancies}></DiscrepanciesList>
        </Box>
    );
};

const CollapsibleRow = ({
    title,
    category1,
    category2,
    category1Total,
    category2Total,
    severity,
    isBunker,
}) => {
    CollapsibleRow.propTypes = {
        title: PropTypes.string.isRequired,
        category1: PropTypes.object.isRequired,
        category2: PropTypes.object.isRequired,
        category1Total: PropTypes.object.isRequired,
        category2Total: PropTypes.object.isRequired,
        severity: PropTypes.string.isRequired,
        isBunker: PropTypes.bool,
    };
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const difference = calculateDifference(category1Total.amount_float > 0 ? category1Total.amount_float : category1Total.calculated_amount,
        category2Total.amount_float > 0 ? category2Total.amount_float : category2Total.calculated_amount);

    const getSeverityColor = () => {
        switch (severity) {
            case "HIGH":
                return theme.palette.semantic.error;
            case "MEDIUM":
                return theme.palette.semantic.warning;
            case "LOW":
                return theme.palette.semantic.success;
            default:
                return "inherit";
        }
    };

    const boldDifferenceStyle = {
        fontWeight: "bold",
        color: getSeverityColor(),
    };

    return (
        <>
            {/* Main Row */}
            <TableRow>
                <TableCell style={{ width: '12%', ...boldDifferenceStyle, ...borderStyle }}>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                        disabled={category1.length === 0 && category2.length === 0}
                        style={category1.length === 0 && category2.length === 0 ? { color: 'transparent' } : {}}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    {title}
                </TableCell>

                <TableCell style={{ width: '20%', ...boldDifferenceStyle, ...borderStyle }}>
                    {category1Total.calculated_amount ? Number(category1Total.calculated_amount).toFixed(2) : '-'}
                </TableCell>
                <TableCell style={{ width: '20%', ...boldDifferenceStyle, ...borderStyle }} >{category1Total.missing ? '-' : category1Total.amount_float}</TableCell>
                <TableCell style={{ width: '20%', ...boldDifferenceStyle, ...borderStyle }}>
                    {category2Total.calculated_amount ? Number(category2Total.calculated_amount).toFixed(2) : '-'}
                </TableCell>
                <TableCell style={{ width: '20%', ...boldDifferenceStyle, ...borderStyle }}>{category2Total.missing ? '-' : category2Total.amount_float}</TableCell>
                <TableCell style={{ width: '15%', ...boldDifferenceStyle }}>
                    {difference}
                </TableCell>
            </TableRow>

            {/* Collapsible Sub-Items Row */}
            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" style={{ width: "100%" }}>
                            <TableBody>
                                {Array.from({ length: Math.max(category1.length, category2.length) }).map(
                                    (_, index, array) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                style={{
                                                    width: '12%',
                                                    borderLeft: '2px solid #2D6BCB',
                                                    borderTop: index === 0 ? '2px solid #2D6BCB' : 'none',
                                                    borderBottom:
                                                        index === array.length - 1 ? '2px solid #2D6BCB' : 'none',
                                                    borderRight: '2px solid #2D6BCB',
                                                    color: getSeverityColor(),
                                                }}
                                            />
                                            <TableCell
                                                style={{
                                                    width: '20%',
                                                    color: getSeverityColor(),
                                                    borderTop: index === 0 ? '2px solid #2D6BCB' : 'none',
                                                    borderBottom:
                                                        index === array.length - 1 ? '2px solid #2D6BCB' : 'none',
                                                }}
                                            >
                                                {isBunker
                                                    ? category1[index]?.total_value_float || "-"
                                                    : category1[index]?.amount_float || "-"}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: '20%',
                                                    color: getSeverityColor(),
                                                    borderTop: index === 0 ? '2px solid #2D6BCB' : 'none',
                                                    borderBottom:
                                                        index === array.length - 1 ? '2px solid #2D6BCB' : 'none',
                                                }}
                                            >
                                                {isBunker
                                                    ? category1[index]?.fuel_name || "-"
                                                    : category1[index]?.field_name || "-"}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: '20%',
                                                    color: getSeverityColor(),
                                                    borderTop: index === 0 ? '2px solid #2D6BCB' : 'none',
                                                    borderBottom:
                                                        index === array.length - 1 ? '2px solid #2D6BCB' : 'none',
                                                }}
                                            >
                                                {isBunker
                                                    ? category2[index]?.total_value_float || "-"
                                                    : category2[index]?.amount_float || "-"}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: '20%',
                                                    color: getSeverityColor(),
                                                    borderTop: index === 0 ? '2px solid #2D6BCB' : 'none',
                                                    borderBottom:
                                                        index === array.length - 1 ? '2px solid #2D6BCB' : 'none',
                                                    borderRight: '2px solid #2D6BCB',
                                                }}
                                            >
                                                {isBunker
                                                    ? category2[index]?.fuel_name || "-"
                                                    : category2[index]?.field_name || "-"}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: '15%',
                                                    color: getSeverityColor(),
                                                    borderTop: index === 0 ? '2px solid #2D6BCB' : 'none',
                                                    borderBottom:
                                                        index === array.length - 1 ? '2px solid #2D6BCB' : 'none',
                                                    borderRight: '2px solid #2D6BCB',
                                                }}
                                            />
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};


const HireStatementTable = ({ comparisonResult, filter, filteredRows, setFilteredRows }) => {
    HireStatementTable.propTypes = {
        comparisonResult: PropTypes.object.isRequired,
        filter: PropTypes.string.isRequired,
        filteredRows: PropTypes.array.isRequired,
        setFilteredRows: PropTypes.func.isRequired,
    };
    const theme = useTheme();

    useEffect(() => {
        const applyFilter = (rows) => {
            return rows.filter((row) => {
                const difference = calculateDifference(
                    row.category1Total.amount_float > 0 ? row.category1Total.amount_float : row.category1Total.calculated_amount,
                    row.category2Total.amount_float > 0 ? row.category2Total.amount_float : row.category2Total.calculated_amount
                );

                if (filter === 'discrepancies') {
                    return difference > 0;
                } else if (filter === 'missing') {
                    return (
                        (row.category1Total.amount_float === 0 &&
                            !row.category1Total.calculated_amount) ||
                        (!row.category2Total.calculated_amount &&
                            row.category2Total.amount_float === 0)
                    );
                }
                return true; // Default show all
            });
        }
        if (comparisonResult) {
            const { hire_statement_1, hire_statement_2, comparison_result } = comparisonResult;
            const rows = [
                {
                    title: 'Gross Hire Total',
                    category1: hire_statement_1.gross_hire_items,
                    category2: hire_statement_2.gross_hire_items,
                    category1Total: hire_statement_1.gross_hire_total,
                    category2Total: hire_statement_2.gross_hire_total,
                    severity: comparison_result.gross_hire_discrepancies[0]?.severity,
                },
                {
                    title: "Off Hire Total",
                    category1: hire_statement_1.off_hire_items,
                    category2: hire_statement_2.off_hire_items,
                    category1Total: hire_statement_1.total_off_hire,
                    category2Total: hire_statement_2.total_off_hire,
                    severity: comparison_result.off_hire_discrepancies[0]?.severity,
                },
                {
                    title: "Net Hire Total",
                    category1: [],
                    category2: [],
                    category1Total: hire_statement_1.total_net_hire,
                    category2Total: hire_statement_2.total_net_hire,
                    severity: comparison_result.net_hire_discrepancies[0]?.severity,
                },
                {
                    title: "Bunker Value on Delivery",
                    category1: hire_statement_1.bunker_value_on_delivery_items,
                    category2: hire_statement_2.bunker_value_on_delivery_items,
                    category1Total: hire_statement_1.bunker_value_on_delivery_total,
                    category2Total: hire_statement_2.bunker_value_on_delivery_total,
                    severity: comparison_result.bunker_value_on_delivery_discrepancies[0]?.severity,
                    isBunker: true,
                },
                {
                    title: "Bunker Value on Completion",
                    category1: hire_statement_1.bunker_value_on_completion_items,
                    category2: hire_statement_2.bunker_value_on_completion_items,
                    category1Total: hire_statement_1.bunker_value_on_completion_total,
                    category2Total: hire_statement_2.bunker_value_on_completion_total,
                    severity: comparison_result.bunker_value_on_completion_discrepancies[0]?.severity,
                    isBunker: true,
                },
                {
                    title: "Owners Expenses Total",
                    category1: hire_statement_1.owners_expenses_items,
                    category2: hire_statement_2.owners_expenses_items,
                    category1Total: hire_statement_1.owners_expenses_total,
                    category2Total: hire_statement_2.owners_expenses_total,
                    severity: comparison_result.owners_expenses_discrepancies[0]?.severity,
                },
                {
                    title: "Charterers Expenses Total",
                    category1: hire_statement_1.charterers_expenses_items,
                    category2: hire_statement_2.charterers_expenses_items,
                    category1Total: hire_statement_1.charterers_expenses_total,
                    category2Total: hire_statement_2.charterers_expenses_total,
                    severity: comparison_result.charterers_expenses_discrepancies[0]?.severity,
                },
                {
                    title: "Other Adjustments Total",
                    category1: hire_statement_1.other_adjustments_items,
                    category2: hire_statement_2.other_adjustments_items,
                    category1Total: hire_statement_1.other_adjustments_total,
                    category2Total: hire_statement_2.other_adjustments_total,
                    severity: comparison_result.other_adjustments_discrepancies[0]?.severity,
                },
                {
                    title: "Payments So Far Total",
                    category1: hire_statement_1.payments_so_far_items,
                    category2: hire_statement_2.payments_so_far_items,
                    category1Total: hire_statement_1.payments_so_far_total,
                    category2Total: hire_statement_2.payments_so_far_total,
                    severity: comparison_result.payments_so_far_discrepancies[0]?.severity,
                },
                {
                    title: "Balance Due",
                    category1: [],
                    category2: [],
                    category1Total: hire_statement_1.balance_due,
                    category2Total: hire_statement_2.balance_due,
                    severity: comparison_result.balance_due_discrepancies[0]?.severity,
                }
            ];
            setFilteredRows(applyFilter(rows)); // Filtered rows based on specific logic
        }
    }, [comparisonResult, filter, setFilteredRows]);

    const boldHeaderStyle = {
        fontWeight: "bold",
        fontSize: "16px",
        borderBottom: "2px solid #2D6BCB",
    };

    return (
        <TableContainer style={{
            borderWidth: 2,
            borderColor: "black",
            borderStyle: "solid",
            backgroundColor: theme.palette.background.hover,
        }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '12%', ...boldHeaderStyle }}></TableCell>
                        <TableCell style={{ width: '20%', ...boldHeaderStyle }}>{comparisonResult.hire_statement_1.short_name}</TableCell>
                        <TableCell style={{ width: '20%', ...boldHeaderStyle }} />
                        <TableCell style={{ width: '20%', ...boldHeaderStyle }}>{comparisonResult.hire_statement_2.short_name}</TableCell>
                        <TableCell style={{ width: '20%', ...boldHeaderStyle }} />
                        <TableCell style={{ width: '15%', ...boldHeaderStyle }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={borderStyle}></TableCell>
                        <TableCell style={allBorderStyle}>
                            <GradientAutoAwesomeIcon fontSize={12} />
                            &nbsp;<Typography
                                sx={{
                                    fontSize: 14,
                                    background: theme.palette.gradients.ai,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    display: 'inline-block', // Ensures proper rendering for inline usage
                                }}
                            >Calculated</Typography>
                        </TableCell>
                        <TableCell style={allBorderStyle}>Extracted</TableCell>
                        <TableCell style={allBorderStyle}>
                            <GradientAutoAwesomeIcon fontSize={12} />
                            &nbsp;<Typography
                                sx={{
                                    fontSize: 14,
                                    background: theme.palette.gradients.ai,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    display: 'inline-block', // Ensures proper rendering for inline usage
                                }}
                            >Calculated</Typography>
                        </TableCell>
                        <TableCell style={allBorderStyle}>Extracted</TableCell>
                        <TableCell style={borderBottomStyle}>Discrepancy</TableCell>
                    </TableRow>
                    {/* Totals and Sub-items Rows */}
                    {filteredRows.map((row, index) => (
                        <CollapsibleRow
                            key={index}
                            title={row.title}
                            category1={row.category1}
                            category2={row.category2}
                            category1Total={row.category1Total}
                            category2Total={row.category2Total}
                            severity={row.severity}
                            isBunker={row.isBunker}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default HireStatementResult;
