import React, { createContext, useContext, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { globalStyles } from "../styles/index";
import { applyGlobalStyles } from "../applyStyles";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProviderComponent = ({ children }) => {
  ThemeProviderComponent.propTypes = {
    children: PropTypes.any,
  };

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    return savedTheme === "true";
  });

  const toggleTheme = () => {
    setIsDarkMode((prev) => {
      const newMode = !prev;
      localStorage.setItem("isDarkMode", newMode);
      document.body.setAttribute("data-theme", newMode ? "dark" : "light");
      if (newMode === "dark") {
        applyGlobalStyles({ body: globalStyles.body });
      } else {
        applyGlobalStyles({ body: globalStyles['[data-theme="light"] body'] });
      }
      return newMode;
    });
  };

  const darkTheme = {
    palette: {
      mode: "dark",
      background: {
        default: "#17191E", // App background
        level1: "#24272F", // Background level 1
        level2: "#1A1C21", // Background level 2
        level3: "#404552", // Background level 3
        hover: "#111316",
        navigation: "#0E1012", // Navigation background
        navigationHover: "#1E1E25", // Hover background for navigation
      },
      primary: {
        main: "#2D6BCB", // Blue 4
        blue2: "#92BDFF", // Blue 2
        blue3: "#3D86F6", // Blue 3
        blue6: "#1A3664", // Blue 6
        contrastText: "#FFFFFF", // High contrast text
      },
      secondary: {
        main: "#31BAAE", // Secondary 2
        dark: "#026E78", // Secondary 1
        contrastText: "#FFFFFF",
      },
      text: {
        primary: "#FFFFFF", // High contrast text
        secondary: "#B4B9C3", // Low contrast text
      },
      gradients: {
        darkBlue: "radial-gradient(144.32% 249.37% at 17.82% 31.06%, #000 0%, #1A3968 100%)",
        buttons: "linear-gradient(296deg, #01060D -35.01%, #3D86F6 70.39%)",
        sidebar: "linear-gradient(50deg, #3D86F6 0.05%, #0A2857 89.99%)",
        terniaryButton: "linear-gradient(305deg, #002C43 -12.67%, #31BAAE 94.03%)",
        ai: "linear-gradient(292deg, #3D86F6 26.96%, #31BAAE 61.92%)",
      },
      semantic: {
        success: "#00CC9B",
        warning: "#FF8B00",
        error: "#DC3058",
        info: "#1668BD",
      },
      additional: {
        purple7: "#7B57E0",
        purple8: "#6246B3",
        orange7: "#F9837C",
        orange8: "#C76963",
        mint7: "#50C099",
        mint8: "#30735C",
      },
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
      h1: { fontSize: "32px", lineHeight: "38px" },
      h2: { fontSize: "28px", lineHeight: "32px" },
      h3: { fontSize: "24px", lineHeight: "28px" },
      h4: { fontSize: "20px", lineHeight: "24px" },
      h5: { fontSize: "16px", lineHeight: "20px" },
      h6: { fontSize: "12px", lineHeight: "18px" },
      body1: { fontSize: "16px", lineHeight: "20px" },
      extraSmall: { fontSize: "10px", lineHeight: "14px" },
      small: { fontSize: "12px", lineHeight: "16px" },
      intro: { fontSize: "24px", lineHeight: "28px" },
      display: { fontSize: "40px", lineHeight: "44px" },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: { fontFamily: "Poppins, sans-serif", fontSize: "32px", lineHeight: "38px" },
          h2: { fontFamily: "Poppins, sans-serif", fontSize: "28px", lineHeight: "32px" },
          h3: { fontFamily: "Poppins, sans-serif", fontSize: "24px", lineHeight: "28px" },
          h4: { fontFamily: "Poppins, sans-serif", fontSize: "20px", lineHeight: "24px" },
          h5: { fontFamily: "Poppins, sans-serif", fontSize: "16px", lineHeight: "20px" },
          h6: { fontFamily: "Poppins, sans-serif", fontSize: "12px", lineHeight: "18px" },
          body1: { fontFamily: "Poppins, sans-serif", fontSize: "16px", lineHeight: "20px", fontWeight: 400 },
          body2: { fontFamily: "Poppins, sans-serif", fontSize: "12px", lineHeight: "16px" },
          display: { fontFamily: "Poppins, sans-serif", fontSize: "40px", lineHeight: "44px" },
          intro: { fontFamily: "Poppins, sans-serif", fontSize: "24px", lineHeight: "28px" },
          small: { fontFamily: "Poppins, sans-serif", fontSize: "12px", lineHeight: "16px" },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#1A1C21", // Background for TextField
            color: "#FFFFFF", // Text color for TextField
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#2D6BCB", // Normal background color
            color: "#FFFFFF", // Text color
            "&:hover": {
              background: "linear-gradient(296deg, #01060D -35.01%, #3D86F6 70.39%)", // Hover gradient
            },
            "&:focus": {
              backgroundColor: "#3D86F6", // Focus background color
            },
            "&:disabled": {
              backgroundColor: "rgba(45, 107, 203, 0.4)", // Disabled background with opacity
            },
          },
          outlined: {
            borderColor: "#3D86F6", // Border color for secondary button
            color: "#3D86F6",
            background: "transparent",
            "&:hover": {
              borderColor: "#1C478C",
              background: "transparent", 
            },
            "&:focus": {
              borderColor: "#1A3664",
              background: "transparent", // Focus background color
            },
          },
          tertiary: {
            background: "linear-gradient(305deg, #002C43 -12.67%, #31BAAE 94.03%)", // Normal tertiary background
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#026E78", // Tertiary hover background
            },
            "&:focus": {
              backgroundColor: "#31BAAE", // Tertiary focus background
            },
            "&:disabled": {
              backgroundColor: "rgba(45, 107, 203, 0.4)", // Disabled tertiary background
            },
          },
        },
      },
    },
  };

  const lightTheme = {
    palette: {
      mode: "light",
      // Add light theme colors here following the same structure.
      primary: {
        main: "#1976D2",
      },
      secondary: {
        main: "#D81B60",
      },
      background: {
        default: "#F5F5F5",
        level1: "#FFFFFF",
        level2: "#EAEAEA",
        navigation: "#FFFFFF",
        navigationHover: "#F0F0F0",
      },
      text: {
        primary: "#000000",
        secondary: "#444444",
      },
      gradients: {
        darkBlue: "radial-gradient(144.32% 249.37% at 17.82% 31.06%, #000 0%, #1A3968 100%)",
        buttons: "linear-gradient(296deg, #01060D -35.01%, #3D86F6 70.39%)",
        sidebar: "linear-gradient(50deg, #3D86F6 0.05%, #0A2857 89.99%)",
        terniaryButton: "linear-gradient(305deg, #002C43 -12.67%, #31BAAE 94.03%)",
        ai: "linear-gradient(292deg, #3D86F6 26.96%, #31BAAE 61.92%)",
      },
      semantic: {
        success: "#00C853",
        warning: "#FF6D00",
        error: "#D32F2F",
        info: "#0288D1",
      },
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
      h4: {
        fontWeight: 600,
      },
    },
  };

  const theme = createTheme(isDarkMode ? darkTheme : lightTheme);

  useEffect(() => {
    document.body.setAttribute("data-theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
