import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { imageToBase64 } from './loiDocxService'
import { getTemplateFunctions } from '../templates/utils';

export const handleSaveAsPDF = async (formData) => {
    const {
        currentDate, vessel, owners, ownersManagers, portLoading, portDischarge,
        cargo, billOfLading, consignee, shipper, requestorParty, deliveryParty,
        requestorName, requestorDesignation, deliveryPlace, address, letterhead, loiTemplate
    } = formData;

    const logoUrl = await imageToBase64(letterhead);
    const logoBase64 = logoUrl.split(",")[1]; // Extract the Base64 part

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Embed the fonts
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesRomanBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    // Add a page
    let page = pdfDoc.addPage([595, 842]); // A4 size dimensions: [595, 842]

    const { width, height } = page.getSize();

    const margin = 40;
    const lineHeight = 20;
    const maxWidth = width - margin * 2;

    // Set up text options
    const textOptions = {
        size: 11,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
        lineHeight: 20,
    };

    const boldTextOptions = {
        size: 11,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
    };

    // Function to draw text on the page with support for indentation and line height
    const drawText = (text, x, y, options = {}) => {
        page.drawText(text, {
            x,
            y,
            ...textOptions,
            ...options,
        });
    };

    // Function to draw bold text
    const drawBoldText = (text, x, y, options = {}) => {
        page.drawText(text, {
            x,
            y,
            ...boldTextOptions,
            ...options,
        });
    };

    const templateText = await getTemplateFunctions[loiTemplate.type]({
        shipper,
        consignee,
        requestorParty,
        deliveryParty,
        deliveryPlace,
        requestorName,
        requestorDesignation,
    });
    const { cargoDescription, indemnityRequest, indemnityTerms, indemnityAgreement, indemnitySublist, signatureText } = templateText;

    // Add the Centurion logo in the header
    const logoImage = await pdfDoc.embedPng(logoBase64);
    const logoDims = logoImage.scale(1); // Scale the logo if needed
    page.drawImage(logoImage, {
        x: width - logoDims.width - 20, // Right align
        y: height - logoDims.height - 20,
        width: logoDims.width,
        height: logoDims.height,
    });
    // Start drawing text content
    let currentY = height - logoDims.height - 40; // Adjust for the logo

    // To section
    drawText("To: ", margin, currentY);
    drawBoldText(ownersManagers, margin + 30, currentY);
    currentY -= 20;

    // Owners and Managers
    drawText("and/or The Owners and/or Managers of the ", margin + 30, currentY);
    drawBoldText(vessel, calculateXAfterText(page, timesRomanFont, "and/or The Owners and/or Managers of the ", textOptions.size, margin + 30), currentY);
    currentY -= 20;

    // Date section
    drawText("Date: ", margin, currentY);
    drawBoldText(currentDate, margin + 30, currentY);
    currentY -= 40;


    // "Dear Sirs" section
    drawText("Dear Sirs,", margin, currentY);
    currentY -= 20;

    // Vessel information
    drawText("Vessel: ", margin + 30, currentY);
    drawBoldText(vessel, calculateXAfterText(page, timesRomanFont, "Vessel: ", textOptions.size, margin + 30), currentY);
    currentY -= 20;

    drawText("Port of Loading: ", margin + 30, currentY);
    drawBoldText(portLoading, calculateXAfterText(page, timesRomanFont, "Port of Loading: ", textOptions.size, margin + 30), currentY);
    currentY -= 20;

    drawText("Port of Discharge: ", margin + 30, currentY);
    drawBoldText(portDischarge, calculateXAfterText(page, timesRomanFont, "Port of Discharge: ", textOptions.size, margin + 30), currentY);
    currentY -= 20;

    drawText("Cargo: ", margin + 30, currentY);
    drawBoldText(cargo, calculateXAfterText(page, timesRomanFont, "Cargo: ", textOptions.size, margin + 30), currentY);
    currentY -= 20;

    drawText("Bill(s) of Lading: ", margin + 30, currentY);
    currentY = drawWrappedText(billOfLading, calculateXAfterText(page, timesRomanFont, "Bill(s) of Lading: ", textOptions.size, margin + 30), currentY, boldTextOptions, page, maxWidth, lineHeight);
    currentY -= 40;

    // Cargo description
    currentY = drawWrappedText(
        cargoDescription,
        margin, currentY, textOptions, page, maxWidth, lineHeight
    );
    currentY -= 30;

    // Indemnity request section
    if (indemnityRequest) {
        currentY = drawWrappedText(
            indemnityRequest,
            margin,
            currentY,
            textOptions,
            page,
            maxWidth,
            lineHeight
        );
        currentY -= 30;
    }

    // Indemnity terms
    drawText(indemnityTerms, margin, currentY);
    currentY -= 30;

    let footerHeight = 15;
    let bottomMargin = 100;

    // Add indemnity agreement text to PDF
    indemnityAgreement.forEach((text, index) => {
        if (currentY < bottomMargin) { // Add a new page if the space is running out
            drawCenteredText(page, timesRomanFont, requestorParty, 10, footerHeight + 10, rgb(0.5, 0.5, 0.5)); // Footer text with margin
            drawCenteredText(page, timesRomanFont, address, 10, footerHeight, rgb(0.5, 0.5, 0.5)); // Footer text with margin
            page = pdfDoc.addPage();
            currentY = height - margin;
            page.drawImage(logoImage, {
                x: width - logoDims.width - 20, // Right align
                y: height - logoDims.height - 20,
                width: logoDims.width,
                height: logoDims.height,
            });
            currentY = height - logoDims.height - 40; // Adjust spacing after logo
        }

        // If it's the third point, add the sublist after the main text
        if (index === 2) {
            // Add main paragraph for point 3
            currentY = drawWrappedText(
                text,
                margin,
                currentY,
                textOptions,
                page,
                maxWidth,
                lineHeight
            );
            currentY -= textOptions.size + 10;

            // Add the sublist under point 3
            indemnitySublist.forEach((subItem) => {
                if (currentY < bottomMargin) { // Add a new page if the space is running out
                    drawCenteredText(page, timesRomanFont, requestorParty, 10, footerHeight + 10, rgb(0.5, 0.5, 0.5)); // Footer text with margin
                    drawCenteredText(page, timesRomanFont, address, 10, footerHeight, rgb(0.5, 0.5, 0.5)); // Footer text with margin
                    page = pdfDoc.addPage();
                    currentY = height - margin;
                    page.drawImage(logoImage, {
                        x: width - logoDims.width - 20, // Right align
                        y: height - logoDims.height - 20,
                        width: logoDims.width,
                        height: logoDims.height,
                    });
                    currentY = height - logoDims.height - 40;
                }
                currentY = drawWrappedText(
                    subItem,
                    margin + 20,
                    currentY,
                    textOptions,
                    page,
                    maxWidth,
                    lineHeight
                );
                currentY -= textOptions.size + 5;
            });
        } else {
            // Add regular paragraphs for other points
            currentY = drawWrappedText(
                text,
                margin,
                currentY,
                textOptions,
                page,
                maxWidth,
                lineHeight
            );
            currentY -= textOptions.size + 10;
        }
    });

    signatureText.forEach((text) => {
        if (currentY < bottomMargin) { // Add a new page if the space is running out
            drawCenteredText(page, timesRomanFont, requestorParty, 10, footerHeight + 10, rgb(0.5, 0.5, 0.5)); // Center requestorParty at y = 50
            drawCenteredText(page, timesRomanFont, address, 10, footerHeight, rgb(0.5, 0.5, 0.5)); // Center address at y = 40
            page = pdfDoc.addPage();
            currentY = height - margin;
            page.drawImage(logoImage, {
                x: width - logoDims.width - 20, // Right align
                y: height - logoDims.height - 20,
                width: logoDims.width,
                height: logoDims.height,
            });
            currentY = height - logoDims.height - 40;
        }

        drawText(text, margin, currentY);
        currentY -= 20;
    });

    // Footer (same text repeated)
    drawCenteredText(page, timesRomanFont, requestorParty, 10, footerHeight + 10, rgb(0.5, 0.5, 0.5)); // Center requestorParty at y = 50
    drawCenteredText(page, timesRomanFont, address, 10, footerHeight, rgb(0.5, 0.5, 0.5)); // Center address at y = 40

    // Save the PDF
    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), `LOI_${owners}_${loiTemplate.type}.pdf`);
};

// Helper function to wrap long text to fit within the page width
const drawWrappedText = (text, x, y, options = {}, page, maxWidth, lineHeight) => {
    // Split the text into words
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    // Loop through the words and add them to the line
    words.forEach((word, index) => {
        const testLine = line + word + ' ';
        const lineWidth = options.font.widthOfTextAtSize(testLine, options.size);

        // If the line width exceeds the max width, draw the line and reset it
        if (lineWidth > maxWidth && index > 0) {
            page.drawText(line, { x, y: currentY, ...options });
            line = word + ' ';
            currentY -= lineHeight;
        } else {
            line = testLine;
        }
    });

    // Draw the last line
    if (line) {
        page.drawText(line, { x, y: currentY, ...options });
    }

    return currentY;
};

// Function to calculate new x position after given text
const calculateXAfterText = (page, font, text, fontSize, startingX) => {
    const textWidth = font.widthOfTextAtSize(text, fontSize); // Measure the width of the text
    return startingX + textWidth; // Calculate the new x position
};

// Function to center text on the page
const drawCenteredText = (page, font, text, fontSize, yPosition, color) => {
    const pageWidth = page.getWidth(); // Get the page width
    const textWidth = font.widthOfTextAtSize(text, fontSize); // Get the width of the text
    const xPosition = (pageWidth - textWidth) / 2; // Calculate the centered x position
    page.drawText(text, {
        x: xPosition,
        y: yPosition,
        size: fontSize,
        font,
        color: color,
    });
};
