import React, { useContext, useState, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    Typography,
    IconButton,
    Tooltip,
    Divider,
    Grid2,
    useTheme,
} from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import EastIcon from '@mui/icons-material/East';

const tools = [
    { id: 1, name: "Voyage Manifest Cargo <-> B/L", category: ["Comparison"], route: "cargo-comparison" },
    { id: 2, name: "(MRs <-> MR Cargo) -> LOI", category: ["Comparison", "Generation"], route: "mr-comparison" },
    { id: 3, name: "MRs <-> B/L", category: ["Comparison"], route: "mr-bl-comparison" },
    { id: 4, name: "Hire Statement", category: ["Comparison"], route: "hire-comparison" },
    { id: 5, name: "Statement of Fact", category: ["Comparison"], route: "sof-comparison" },
    { id: 6, name: "B/L -> LOI", category: ["Generation"], route: "bl-to-loi" },
    { id: 7, name: "LOI -> LOI", category: ["Generation"], route: "loi-to-loi" },
    { id: 8, name: "Recap -> Instructions", category: ["Generation"], route: "recap-to-instructions" },
];

const Dashboard = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [favorites, setFavorites] = useState(() => {
        const savedFavorites = localStorage.getItem(`favorites_${user?.id}`);
        return savedFavorites ? JSON.parse(savedFavorites) : [];
    });

    useEffect(() => {
        localStorage.setItem(`favorites_${user?.id}`, JSON.stringify(favorites));
    }, [favorites, user]);

    const handleFavoriteToggle = (toolId) => {
        setFavorites((prevFavorites) =>
            prevFavorites.includes(toolId)
                ? prevFavorites.filter((id) => id !== toolId)
                : [...prevFavorites, toolId]
        );
    };

    const favoriteTools = tools.filter((tool) => favorites.includes(tool.id));
    const nonFavoriteTools = tools.filter((tool) => !favorites.includes(tool.id));

    const renderTools = (toolsList) =>
        toolsList.map((tool) => (
            <Grid2 item size={{ xs: 12, sm: 6, md: 3 }} key={tool.id}>
                <Card
                    sx={{
                        backgroundColor: theme.palette.background.level2,
                        color: "inherit",
                        position: "relative",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                        cursor: "pointer",
                        borderRadius: 3,
                        overflow: 'hidden', // Ensures the border effect is clipped
                        zIndex: 0,
                        ':before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            zIndex: -1,
                            borderRadius: 3,
                            padding: '1px', // Border width
                            WebkitMask: `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
                            WebkitMaskComposite: 'destination-out',
                            maskComposite: 'exclude',
                        },
                    }}
                    onClick={() => navigate(`/dashboard/${tool.route}`)}
                >
                    <CardContent>
                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 6 }}>
                            <Box display="flex" alignItems="center">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFavoriteToggle(tool.id);
                                    }}
                                    sx={{ color: favorites.includes(tool.id) ? "#E7BA52" : theme.palette.background.level3 }}
                                >
                                    {favorites.includes(tool.id) ? <Star /> : <StarBorder />}
                                </IconButton>
                                {tool.category.map((cat, index) => (
                                    <Typography
                                        key={cat}
                                        variant="body1"
                                        sx={{
                                            display: "inline-block",
                                            color:
                                                cat === "Comparison"
                                                    ? theme.palette.primary.blue3
                                                    : theme.palette.secondary.light,
                                            ml: 1,
                                        }}
                                    >
                                        {cat}&nbsp;
                                        {index < tool.category.length - 1 && (
                                            <span style={{color: theme.palette.text.secondary}}>
                                            |
                                            </span>
                                        )}
                                    </Typography>
                                ))}
                            </Box>
                            <Tooltip title="Go to Tool">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/dashboard/${tool.route}`);
                                    }}
                                    sx={{
                                        "&:hover": {
                                            backgroundImage: theme.palette.gradients.ai,
                                        },
                                    }}
                                >
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Typography variant="intro" sx={{ mt: 1, lineHeight: 2 }}>
                            {tool.name.split(/(<->|->)/).map((part, index) => {
                                if (part === "->") {
                                    return (
                                        <EastIcon
                                            key={index}
                                            sx={{ color: theme.palette.secondary.light, mx: 1, verticalAlign: "middle" }}
                                        />
                                    );
                                } else if (part === "<->") {
                                    return (
                                        <CompareArrowsIcon
                                            key={index}
                                            sx={{ color: theme.palette.primary.main, mx: 1, verticalAlign: "middle" }}
                                        />
                                    );
                                }
                                return <React.Fragment key={index}>{part.trim()}</React.Fragment>;
                            })}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid2>
        ));

    return (
        <Box sx={{ p: 5, mx: 5 }}>
            <Box>
                <Typography
                    variant="display"
                    color={theme.palette.text.primary}
                    sx={{ fontWeight: 275 }}
                >
                    AI
                </Typography>
                <Typography
                    variant="display"
                    color={theme.palette.text.primary}
                    sx={{ fontWeight: 500 }}
                >
                    Toolkit
                </Typography>
            </Box>
            <Box sx={{ mb: 5 }}>
                <Typography
                    variant="intro"
                    color={theme.palette.text.secondary}
                    sx={{ fontWeight: 275 }}
                >
                    Your Intelligent Toolkit
                </Typography>
            </Box>

            {favoriteTools.length > 0 && (
                <>
                    <Grid2 container spacing={3}>{renderTools(favoriteTools)}</Grid2>
                    <Divider
                        sx={{
                            my: 4,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            background: theme.palette.primary.main
                        }}
                    />
                </>
            )}
            <Grid2 container spacing={3}>{renderTools(nonFavoriteTools)}</Grid2>
        </Box>
    );
};

export default Dashboard;
