import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Divider,
    Typography,
    Modal,
    List,
    ListItem,
    ListItemText,
    Tooltip,
} from "@mui/material";
import { handleSaveAsWord } from "../../services/loiDocxService"
import { handleSaveAsPDF } from "../../services/loiPdfService";
import { sendFieldFeedback } from "../../services/feedbackService";
import { UserContext } from '../../context/UserContext';
import { getTemplateFunctions } from '../../templates/utils';
import PropTypes from 'prop-types';

export const IndemnityModal = ({
    open,
    onClose,
    currentDate,
    details,
    requestorName,
    requestorCompany,
    requestorDesignation,
    owners,
    letterheadImage,
    loiTemplate,
    requestId,
    address,
}) => {
    IndemnityModal.propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        currentDate: PropTypes.string,
        details: PropTypes.object,
        requestorName: PropTypes.string,
        requestorCompany: PropTypes.string,
        requestorDesignation: PropTypes.string,
        owners: PropTypes.string,
        letterheadImage: PropTypes.string,
        loiTemplate: PropTypes.object,
        requestId: PropTypes.string,
        address: PropTypes.string,
    };
    const [trackedChanges, setTrackedChanges] = useState({});
    const { user } = useContext(UserContext);
    const [template, setTemplate] = useState(null);

    // Define a style for the modal box
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%", // To limit height and allow scroll
        bgcolor: "background.paper",
        border: "1px solid #ccc", // Light border
        borderRadius: "10px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Softer shadow
        display: "flex",
        flexDirection: "column", // Ensures sticky footer and scrolling content
        p: 4,
    };

    // Define the style for the modal content (scrollable area)
    const contentStyle = {
        flex: 1,
        overflowY: "auto", // Allow vertical scroll
        padding: "16px",
        marginBottom: "60px", // Space for sticky buttons
    };

    // Highlight the <strong> tag with a different color
    const strongStyle = {
        color: "#008000", // Use a different shade (blue for example)
        fontWeight: "bold",
    };

    // Helper function to get the value or return "MISSING"
    const getValue = (key) => {
        const value = details[key]?.value;
        return value || "MISSING";
    };

    const determineDeliveryParty = (deliveryParty, consigneeDetails) => {
        // Check if deliveryParty has a value
        if (deliveryParty) {
            // Check if consigneeDetails contains deliveryParty and is longer
            if (consigneeDetails.includes(deliveryParty) && consigneeDetails.length > deliveryParty.length) {
                return consigneeDetails;
            }
            // Return the existing deliveryParty if conditions aren't met
            return deliveryParty;
        }
        // If deliveryParty is null, set it to consigneeDetails
        return consigneeDetails;
    };

    // Function to add or update tracked changes with document IDs
    const trackChange = (field, newValue) => {
        setTrackedChanges((prevChanges) => {
            const originalValue = formData[field];
            // Only add to trackedChanges if the value is actually changed
            if (originalValue !== newValue) {
                return {
                    ...prevChanges,
                    [field]: { value: newValue },
                };
            } else {
                // Remove from trackedChanges if it reverts back to original
                // eslint-disable-next-line no-unused-vars
                const { [field]: _, ...remainingChanges } = prevChanges;
                return remainingChanges;
            }
        });
    };

    const sendTrackChangesToBackend = async () => {
        try {
            const result = await sendFieldFeedback(user.orgId, requestId, trackedChanges, "MR");
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    };

    const [formData, setFormData] = useState({
        currentDate: currentDate,
        vessel: getValue("vessel_name"),
        owners: getValue("owners_managers"),
        ownersManagers: owners ? owners : getValue("owners_managers"),
        portLoading: getValue("port_of_loading"),
        portDischarge: getValue("port_of_discharge"),
        cargo: getValue("cargo_description"),
        billOfLading: getValue("bill_of_lading_details"),
        consignee: getValue("consignee_details"),
        shipper: getValue("shipper_details"),
        deliveryParty: determineDeliveryParty(getValue("delivery_party"), getValue("consignee_details")),
        requestorParty: requestorCompany,
        requestorName: requestorName,
        requestorDesignation: requestorDesignation,
        deliveryPlace: getValue("delivery_place") === "MISSING" ? getValue("port_of_discharge") : getValue("delivery_place"),
        address: address,
        letterhead: letterheadImage,
        loiTemplate: loiTemplate
    });


    // useEffect to update formData.letterhead whenever letterheadImage changes
    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            letterhead: letterheadImage,
            loiTemplate: loiTemplate
        }));

    }, [letterheadImage, loiTemplate]);

    // useEffect to update template whenever loiTemplate changes
    // separate to prevent infinite rerenders
    useEffect(() => {
        if (!loiTemplate) return;

        // Gets the template based on the template type
        const newTemplate = getTemplateFunctions[loiTemplate.type]({
            shipper: formData.shipper,
            consignee: formData.consignee,
            requestorParty: formData.requestorParty,
            deliveryParty: formData.deliveryParty,
            deliveryPlace: formData.deliveryPlace,
            requestorName: formData.requestorName,
            requestorDesignation: formData.requestorDesignation,
        });

        setTemplate(newTemplate);
    }, [formData, letterheadImage, loiTemplate]);


    // Function to copy modal content to clipboard
    const handleCopyToClipboard = () => {
        const element = document.getElementById("modal-content");
        const textToCopy = element.innerText || element.textContent; // Get only the text content
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                alert("Text copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
        sendTrackChangesToBackend();
    };

    // Function to update formData for simple fields
    const updateFormData = (key, value) => {
        setFormData((prevData) => {
            // Track changes before updating formData (no document ID for simple fields)
            trackChange(key, value);
            return {
                ...prevData,
                [key]: value,
            };
        });
    };

    // Function to render text template
    const renderTextTemplate = (value, contextKey, nullContext, valueName) => (
        <TextTemplate
            value={value}
            context={details[contextKey]?.context}
            nullContext={nullContext}
            confidence={details[contextKey]?.confidence}
            updateFormData={updateFormData}
            valueName={valueName}
        />
    );

    const vesselTemplate = renderTextTemplate(
        formData.vessel, "vessel_name", "Vessel Name", "vessel"
    );
    const portLoadingTemplate = renderTextTemplate(
        formData.portLoading, "port_of_loading", "Port of Loading", "portLoading"
    );
    const portDischargeTemplate = renderTextTemplate(
        formData.portDischarge, "port_of_discharge", "Port of Discharge", "portDischarge"
    );
    const cargoDescriptionTemplate = renderTextTemplate(
        formData.cargo, "cargo_description", "Cargo Description", "cargo"
    );
    const billOfLadingTemplate = renderTextTemplate(
        formData.billOfLading, "bill_of_lading", "Bill of Lading", "billOfLading"
    );

    const shipperTemplate = renderTextTemplate(
        formData.shipper, "shipper_details", "Shipper Name", "shipper"
    );
    const consigneeTemplate = renderTextTemplate(
        formData.consignee, "consignee_details", "Consignee Name", "consignee"
    );
    const deliveryPlaceTemplate = renderTextTemplate(
        formData.deliveryPlace, "delivery_place", "Delivery Place", "deliveryPlace"
    );

    const deliveryPartyTemplate = renderTextTemplate(
        formData.deliveryParty, "delivery_party", "Delivery Party", "deliveryParty"
    );

    // Function to render cargo description based on template type
    const renderCargoDescription = () => {
        if (!loiTemplate) return null;
        switch (loiTemplate.type) {
            case "A":
                return (
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        The above cargo was shipped on the above vessel by&nbsp;
                        {shipperTemplate}
                        &nbsp;and consigned to&nbsp;
                        {consigneeTemplate}
                        &nbsp;for delivery at the Port of Discharge but the Bill(s) of
                        Lading is (are) not currently available to be presented.
                    </Typography>
                );

            case "B":
                return (
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        The above cargo was shipped on the above vessel by&nbsp;
                        {shipperTemplate}
                        &nbsp;and consigned to&nbsp;
                        {consigneeTemplate}
                        &nbsp;for delivery at the Port of Discharge but we, {formData.requestorParty}&nbsp;
                        hereby request you to order the Vessel to proceed to and deliver the said cargo at&nbsp;
                        {deliveryPlaceTemplate}
                        &nbsp;against production of at least one original Bill(s) of Lading.
                    </Typography>
                );

            case "C":
                return (
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        The above cargo was shipped on the above vessel by&nbsp;
                        {shipperTemplate}
                        &nbsp;and consigned to&nbsp;
                        {consigneeTemplate}
                        &nbsp;for delivery at the Port of Discharge but we, {formData.requestorParty}&nbsp;
                        hereby request you to order the Vessel to proceed to and deliver the said cargo at&nbsp;
                        {deliveryPlaceTemplate}
                        .
                    </Typography>
                );
            default:
                return null;
        }
    }

    // Function to render indemnity request based on template type
    const renderIndemnityRequest = () => {
        if (!loiTemplate) return null;
        switch (loiTemplate.type) {
            case "A":
                return (
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        We, {formData.requestorParty} hereby represent and undertake that&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;is the party lawfully entitled to delivery of the said Cargo and request you to deliver the said Cargo to&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;or to such party as you believe to be or to represent&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;or to be acting on behalf of&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;at&nbsp;
                        {deliveryPlaceTemplate}
                        &nbsp;without production of the original Bill(s) of Lading.&nbsp;
                    </Typography>
                );
            case "C":
                return (
                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        Further, as the Bill(s) of Lading is (are) not currently available to be
                        presented, we also hereby represent and undertake that&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;is the party lawfully entitled to delivery of the said Cargo and
                        request you to deliver the said Cargo to&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;or to such party as you believe to be or to represent&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;or to be acting on behalf of&nbsp;
                        {deliveryPartyTemplate}
                        &nbsp;at&nbsp;
                        {deliveryPlaceTemplate}
                        &nbsp;without production of the original Bill(s) of Lading.&nbsp;
                    </Typography>
                )
            default:
                return null;
        }
    }

    // Function to render indemnity agreement text for template
    const renderIndemnityAgreementText = () => {
        if (!template) return null;
        let indemnityList = template.indemnityAgreement;
        return (
            <List sx={{ listStyle: "none", marginLeft: '-1rem' }}>
                {indemnityList.map((item, index) => (
                    <ListItem key={index} sx={{ display: "list-item" }}>
                        <ListItemText primary={item} />
                        {index === 2 && (
                            <List sx={{ paddingLeft: 4 }}>
                                {template.indemnitySublist.map((subItem, subIndex) => (
                                    <ListItem key={subIndex} sx={{ display: 'list-item' }}>
                                        <ListItemText primary={subItem} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </ListItem>
                ))}
            </List>
        );
    }

    // Function to render indemnity terms for template
    const renderIndemnityTerms = () => {
        if (!template) return null;
        return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
                {template.indemnityTerms}
            </Typography>
        );
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle} >
                <Typography variant="h4">Letter of Indemnity</Typography>

                <Box sx={contentStyle} id="modal-content">
                    <Typography variant="body1">
                        To: <strong style={strongStyle}>{owners ? owners : getValue("owners_managers")}</strong>
                    </Typography>

                    <Typography variant="body1">
                        and/or the Owners and/or Managers of the&nbsp;
                        {vesselTemplate}
                    </Typography>

                    <Typography variant="body1">
                        Date: <strong style={strongStyle}>{currentDate}</strong>
                    </Typography>

                    <Typography variant="body1">
                        The Vessel:&nbsp;
                        {vesselTemplate}
                    </Typography>

                    <Typography variant="body1">
                        Port of Loading:&nbsp;
                        {portLoadingTemplate}
                    </Typography>

                    <Typography variant="body1">
                        Port of Discharge:&nbsp;
                        {portDischargeTemplate}
                    </Typography>

                    <Typography variant="body1">
                        Cargo:&nbsp;
                        {cargoDescriptionTemplate}
                    </Typography>

                    <Typography variant="body1">
                        Bill of Lading:&nbsp;
                        {billOfLadingTemplate}
                    </Typography>

                    <Divider sx={{ marginY: 2 }} />

                    <Typography variant="body1">Dear Sirs,</Typography>

                    {/* Rendered based on Loi Template Type */}
                    {renderCargoDescription()}
                    {renderIndemnityRequest()}
                    {renderIndemnityTerms()}
                    {renderIndemnityAgreementText()}

                    <Typography variant="body1" sx={{ marginTop: 2 }}>
                        Yours faithfully
                    </Typography>
                    <Typography variant="body1">For and on behalf of</Typography>
                    <Typography variant="body1"><strong style={strongStyle}>{requestorCompany}</strong></Typography>
                    <Typography variant="body1">The Requestor</Typography>
                    <Typography variant="body1">Signature …………………………………</Typography>
                    <Typography variant="body1">
                        Name: <strong style={strongStyle}>{requestorName}</strong>
                    </Typography>
                    <Typography variant="body1">
                        Title: <strong style={strongStyle}>{requestorDesignation}</strong>
                    </Typography>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
                    <Button onClick={() => { sendTrackChangesToBackend(); handleSaveAsPDF(formData) }}>Save as PDF</Button>
                    <Button onClick={() => { sendTrackChangesToBackend(); handleSaveAsWord(formData) }}>Save as Word</Button>
                    <Button onClick={onClose}>Close</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export const TextTemplate = ({
    value,
    context,
    nullContext,
    confidence,
    updateFormData,
    valueName,
}) => {

    TextTemplate.propTypes = {
        value: PropTypes.string.isRequired,
        context: PropTypes.string,
        nullContext: PropTypes.string,
        confidence: PropTypes.string,
        updateFormData: PropTypes.func,
        valueName: PropTypes.string,
    };
    // Function to get the style dynamically based on the value
    const getStrongStyle = (value) => {
        let color = 'inherit';
        switch (value) {
            case "XXXX":
                color = 'orange';
                break;
            case "MISSING":
                color = 'red';
                break;
            default:
                color = 'green';
                break;
        }
        return {
            color: color,
            fontWeight: "bold",
        };
    };

    // Function to determine the color of the confidence level in the tooltip
    const getTooltipStyle = (confidence) => {
        switch (confidence) {
            case "HIGH":
                return { color: "green" };
            case "MEDIUM":
                return { color: "yellow" };
            case "LOW":
                return { color: "red" };
            default:
                return { color: "gray" };
        }
    };

    return (
        <Tooltip
            title={
                <Box>
                    <Typography>
                        Context: {context ? context : nullContext}
                    </Typography>
                    <Typography
                        style={getTooltipStyle(confidence)}
                    >
                        Confidence:{" "}
                        {confidence || "Unknown"}
                    </Typography>
                </Box>
            }
        >
            <Typography
                component="span"
                display={"inline"}
                suppressContentEditableWarning={true}
                contentEditable={true}
                onInput={(e) => updateFormData(valueName, e.currentTarget.textContent)}
            >
                <strong style={getStrongStyle(value)}>
                    {value}
                </strong>
            </Typography>
        </Tooltip>
    );
};
