import React from 'react';
import { Box, Typography, IconButton, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import PDFViewer from "./PDFViewer";
import PropTypes from 'prop-types';

const DocumentUploader = ({ files, setFiles, title, setResult }) => {
    DocumentUploader.propTypes = {
        files: PropTypes.array.isRequired, 
        setFiles: PropTypes.func,
        title: PropTypes.string,
        setResult: PropTypes.func,
    };

    const deleteFile = (file, section, setFiles) => {
        const updatedFiles = Array.isArray(section)
            ? section.filter((f) => f.name !== file.name) // Remove from array if files is an array
            : null; // If it's a single file, set to null
        setFiles(updatedFiles);
        setResult(null);
    };

    const handleDropSection = (acceptedFiles) => {
        if (Array.isArray(files)) {
            setFiles([...files, ...acceptedFiles]);
        } else {
            setFiles(acceptedFiles.length > 1 ? acceptedFiles : acceptedFiles[0]);
        }
    };

    const { getRootProps: getRootPropsSection, getInputProps: getInputPropsSection } = useDropzone({
        onDrop: handleDropSection,
        accept: {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/vnd.ms-excel": [".xlsx"]
        }
    });

    // Normalize `files` to an array for consistent rendering
    const normalizedFiles = Array.isArray(files) ? files : files ? [files] : [];

    return (
        <>
            <Box sx={{ border: "1px solid gray", p: 3, borderRadius: 2 }}>
                <Typography>{title}</Typography>
                <Box
                    {...getRootPropsSection()}
                    sx={{
                        border: "2px dashed #ccc",
                        padding: 3,
                        textAlign: "center",
                        mt: 2,
                        borderRadius: 2,
                    }}
                >
                    <input {...getInputPropsSection()} />
                    <CloudUploadIcon sx={{ fontSize: "3rem" }} />
                    <Typography>Drag and drop files here or</Typography>
                    <Button variant="contained" sx={{ mt: 2 }}>
                        Browse Files
                    </Button>
                </Box>
                {/* Display Uploaded Files */}
                {normalizedFiles.map((file, index) => (
                    <Box key={`document: ${index}`}>
                        <Typography key={index} sx={{ mt: 2 }}>
                            {file.name}
                            <IconButton
                                onClick={() =>
                                    deleteFile(file, normalizedFiles, setFiles)
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                ))}
            </Box>
            {/* PDF Viewer Section */}
            {files && (
                <PDFViewer file={files}></PDFViewer>
            )}
        </>
    );
};

export default DocumentUploader;
