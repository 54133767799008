import React, { useState, useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { exportToExcel, copyToClipboard, copyToClipboardDetail, exportToExcelDetail } from '../../helpers'
import {
    Box, Grid2, Typography, Card, CardContent, Divider, Button, Collapse, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';

const SOFResult = ({ result }) => {
    SOFResult.propTypes = {
        result: PropTypes.object,
    };
    const [sofComparisonResult, setSofComparisonResult] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [sanityExpanded, setSanityExpanded] = useState(false);
    const [statementExpanded, setStatementExpanded] = useState(false);

    useEffect(() => {
        setSofComparisonResult(result);
    }, [result]);

    if (!sofComparisonResult) return null;

    const { agent_sof, captain_sof, comparison_result, comparison_time, pdf_storage_time, extraction_time } = sofComparisonResult;
    const total_time = comparison_time + pdf_storage_time + extraction_time;
    const signatureFields = [
        'is_captain_signature_present',
        'is_captain_stamps_present',
        'is_agent_signature_present',
        'is_agent_stamps_present',
    ];

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                SOF Comparison Result
            </Typography>

            <Button
                variant="text"
                fullWidth
                onClick={() => setExpanded(!expanded)}
                sx={{ mt: 3 }}
                style={{ justifyContent: "flex-start" }}
            >
                {`Response time: ${total_time.toFixed(2)} seconds`}
            </Button>
            <Collapse in={expanded}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>Total Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">
                            {total_time.toFixed(2)} seconds
                        </Typography>
                        <Typography sx={{ mb: 1 }}>PDF Storage Time</Typography>
                        <Typography variant="h4">
                            {pdf_storage_time.toFixed(2)} seconds
                        </Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>Comparison Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">
                            {comparison_time.toFixed(2)} seconds
                        </Typography>
                        <Typography sx={{ mb: 1 }}>Extraction Time</Typography>
                        <Typography variant="h4">
                            {extraction_time.toFixed(2)} seconds
                        </Typography>
                    </Grid2>
                </Grid2>
            </Collapse>

            {/* Sanity Check with Collapse */}
            <Button
                variant="text"
                fullWidth
                onClick={() => setSanityExpanded(!sanityExpanded)}
                sx={{ my: 2, justifyContent: "flex-start", typography: 'h5', textTransform: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}
            >
                Sanity Check:&nbsp; <span style={{ color: 'green' }}>{comparison_result.voyage_matching_score}%</span>
                <ExpandMoreIcon
                    sx={{
                        marginLeft: 'auto',
                        transition: 'transform 0.3s',
                        transform: sanityExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                />
            </Button>
            <Collapse in={sanityExpanded}>
                <Card>
                    <CardContent>
                        <Typography variant="body1">
                            {comparison_result.sanity_check}
                        </Typography>
                    </CardContent>
                </Card>
            </Collapse>

            {/* Signature Check */}
            <Typography variant="h5" sx={{ my: 2, justifyContent: "flex-start", typography: 'h5', textTransform: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}>
                Signature Check
            </Typography>
            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: 400,
                    maxWidth: 600,
                    overflowY: 'auto',
                    borderRadius: 2,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    mt: 2,
                    my: 2
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Category</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Captain Signature</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Captain Stamp</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Agent Signature</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Agent Stamp</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Page Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Agent SOF Row */}
                        <TableRow>
                            <TableCell>Agent SOF</TableCell>
                            {signatureFields.map((field) => (
                                <TableCell
                                    key={field}
                                    style={{
                                        backgroundColor: agent_sof.signature_detector[field] ? 'transparent' : 'red',
                                    }}
                                >
                                    <Checkbox
                                        checked={agent_sof.signature_detector[field]}
                                        icon={<CloseIcon />} // Display X when unchecked
                                        checkedIcon={<CheckIcon />} // Display checkmark when checked
                                    />
                                </TableCell>

                            ))}
                            <TableCell>{agent_sof.signature_detector.page_number}</TableCell>
                        </TableRow>
                        {/* Captain SOF Row */}
                        <TableRow>
                            <TableCell>Captain SOF</TableCell>
                            {signatureFields.map((field) => (
                                <TableCell
                                    key={field}
                                    style={{
                                        backgroundColor: captain_sof.signature_detector[field] ? 'transparent' : 'red',
                                    }}
                                >
                                    <Checkbox
                                        checked={captain_sof.signature_detector[field]}
                                        icon={<CloseIcon />} // Display X when unchecked
                                        checkedIcon={<CheckIcon />} // Display checkmark when checked
                                    />
                                </TableCell>
                            ))}
                            <TableCell>{captain_sof.signature_detector.page_number}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Summary */}
            <Typography variant="h5" gutterBottom>
                Summary
            </Typography>
            <Card>
                <CardContent>
                    <Typography variant="body1">
                        {comparison_result.summary}
                    </Typography>
                </CardContent>
            </Card>

            {/* Statement of Facts Details with Collapse */}
            <Button
                variant="text"
                fullWidth
                onClick={() => setStatementExpanded(!statementExpanded)}
                sx={{ my: 2, justifyContent: "flex-start", typography: 'h5', textTransform: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}
            >
                Statement of Facts Details
                <ExpandMoreIcon
                    sx={{
                        marginLeft: 'auto',
                        transition: 'transform 0.3s',
                        transform: statementExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                />
            </Button>
            <Collapse in={statementExpanded}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{ xs: 12, md: 6 }}>
                        <SOFDetails SOFFile={agent_sof} title="Agent" />
                    </Grid2>
                    <Grid2 item size={{ xs: 12, md: 6 }}>
                        <SOFDetails SOFFile={captain_sof} title="Master" />
                    </Grid2>
                </Grid2>
            </Collapse>

            <ComparisonResult comparisonResult={comparison_result.sof_comparison_details} title="Agent vs Master Comparison" />
            <ComparisonResult comparisonResult={comparison_result.core_comparison_details} title="Core Comparison Results" />
            <SimpleComparison eventDetails={comparison_result.missing_event_details} title="Missing Events"></SimpleComparison>
        </Box>
    );
};

const SOFDetails = ({ SOFFile, title }) => {
    SOFDetails.propTypes = {
        SOFFile: PropTypes.object,
        title: PropTypes.string,
    };
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {title} SOF - {SOFFile.vessel}
                </Typography>
                <Typography variant="body2">Vessel: {SOFFile.vessel}</Typography>
                <Typography variant="body2">Voyage Number: {SOFFile.voyage_number}</Typography>
                <Typography variant="body2">Port: {SOFFile.port}</Typography>
                <Typography variant="body2">Last Port: {SOFFile.last_port}</Typography>
                <Typography variant="body2">Next Port: {SOFFile.next_port}</Typography>
                <Divider sx={{ my: 2 }} />
                <SimpleComparison eventDetails={SOFFile.event_details} title={"Event Details"}></SimpleComparison>
            </CardContent>
        </Card>
    );
};

const SimpleComparison = ({ eventDetails, title }) => {
    SimpleComparison.propTypes = {
        eventDetails: PropTypes.object,
        title: PropTypes.string,
    };

    const showMissingFromColumn = eventDetails.some(row => row.present_in_agent_sof !== undefined);

    return (
        <>
            <Box sx={{ display: 'flex', gap: 1, marginBottom: 2, marginTop: 2 }}>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <Tooltip title="Copy to Clipboard" arrow>
                    <IconButton onClick={() => copyToClipboard(eventDetails)} >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export to Excel" arrow>
                    <IconButton onClick={() => exportToExcel(eventDetails)}>
                        <SaveAltIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto', borderRadius: 3, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Event</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Times</TableCell>
                            {showMissingFromColumn && (
                                <TableCell sx={{ fontWeight: 'bold' }}>Missing From</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventDetails.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.event}</TableCell>
                                <TableCell>{row.from_time} - {row.to_time}</TableCell>
                                {showMissingFromColumn && (
                                    <TableCell style={{ backgroundColor: row.present_in_agent_sof ? 'red' : 'green', color: showMissingFromColumn ? 'white' : 'inherit' }}>
                                        {row.present_in_agent_sof ? "Master" : "Agent"}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const ComparisonResult = ({ comparisonResult, title }) => {
    ComparisonResult.propTypes = {
        comparisonResult: PropTypes.object,
        title: PropTypes.string,
    };
    return (
        <>
            <Box sx={{ display: 'flex', gap: 1, marginBottom: 2, marginTop: 2 }}>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <Tooltip title="Copy to Clipboard" arrow>
                    <IconButton onClick={() => copyToClipboardDetail(comparisonResult)} >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Export to Excel" arrow>
                    <IconButton onClick={() => exportToExcelDetail(comparisonResult)}>
                        <SaveAltIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: 400, // Limits table height to display 10 rows approximately
                    overflowY: 'auto',
                    borderRadius: 2,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', }}>#</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', }}>Agent Event</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', }}>Master Event</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', }}>Agent Times</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', }}>Master Times</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', }}>Difference</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comparisonResult.map((row, index) => (
                            <TableRow key={index} >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell style={{ backgroundColor: row.type_of_difference === "EVENT_NAME_DIFFERENCE" ? 'grey' : 'inherit', color: row.type_of_difference === "EVENT_NAME_DIFFERENCE" ? 'white' : 'inherit' }}>{row.agent_event}</TableCell>
                                <TableCell style={{ backgroundColor: row.type_of_difference === "EVENT_NAME_DIFFERENCE" ? 'grey' : 'inherit', color: row.type_of_difference === "EVENT_NAME_DIFFERENCE" ? 'white' : 'inherit' }}>{row.captain_event}</TableCell>
                                <TableCell style={{ backgroundColor: row.type_of_difference === "TIME_DIFFERENCE" ? 'red' : 'inherit', color: row.type_of_difference === "TIME_DIFFERENCE" ? 'white' : 'inherit' }}>{row.agent_from_time} - {row.agent_to_time}</TableCell>
                                <TableCell style={{ backgroundColor: row.type_of_difference === "TIME_DIFFERENCE" ? 'red' : 'inherit', color: row.type_of_difference === "TIME_DIFFERENCE" ? 'white' : 'inherit' }}>{row.captain_from_time} - {row.captain_to_time}</TableCell>
                                <TableCell>{row.difference_description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default SOFResult;