import axios from "axios";
import { API_URL } from "./config";

export const compareSOFFiles = async (
    agentFile, captainFile,
    options = {
        useOCR: true,
        useVision: true,
        useDetailCache: true,
        useComparisonCache: true,
        agentPageRanges: null,
        masterPageRanges: null,
        organisationId: "", // you can set a default organisation ID if needed
    }
) => {
    const formData = new FormData();
    formData.append('agent_sof_file', agentFile); 
    formData.append('captain_sof_file', captainFile); 
    formData.append("ocr", options.useOCR);
    formData.append("vision", options.useVision);
    formData.append("use_sof_detail_cache", options.useDetailCache);
    formData.append('use_sof_comparison_cache', options.useComparisonCache)
    formData.append('agent_page_ranges', JSON.stringify(options.agentPageRanges));
    formData.append('captain_page_ranges', JSON.stringify(options.masterPageRanges));
    formData.append("organisation_id", options.organisationId);

    try {
        const response = await axios.post(`${API_URL}/sof_comparison/compare_sofs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true, // If you're sending credentials like cookies
        });

        return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
    } catch (error) {
        console.error('Error comapring SOF files:', error);
        throw error;
    }
};
