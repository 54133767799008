import { getLoiATemplate } from "./loiATemplate.js";
import { getLoiBTemplate } from "./loiBTemplate.js";
import { getLoiCTemplate } from "./loiCTemplate.js";
import { getChristianiaVITemplate } from "./ChristianiaVITemplate.js";
import { getCenturionVITemplate } from "./CenturionVITemplate.js";
import { getFalconVITemplate } from "./FalconVITemplate.js";

export const loiTemplates = [
    { label: "LOI A - no bill", type: "A" },
    { label: "LOI B - other port", type: "B" },
    { label: "LOI C - no bill and other port", type: "C" },
];

export const voyageInstructionTemplates = {
    centurion: "CENTURION_VOYAGE_INSTRUCTIONS",
    christiania: "CHRISTIANIA_VOYAGE_INSTRUCTIONS",
    falcon: "FALCON_VOYAGE_INSTRUCTIONS",
    default: "CHRISTIANIA_VOYAGE_INSTRUCTIONS",
};
export const getTemplateFunctions = {
    A: getLoiATemplate,
    B: getLoiBTemplate,
    C: getLoiCTemplate,
    CHRISTIANIA_VOYAGE_INSTRUCTIONS: getChristianiaVITemplate,
    CENTURION_VOYAGE_INSTRUCTIONS: getCenturionVITemplate,
    FALCON_VOYAGE_INSTRUCTIONS: getFalconVITemplate,
}

// Splits text into lines with newlines as ""
export const splitTextToLines = (text) => {
    const result = text
        .split("\n") 
        .map(line => line.trim()) 
        .filter(line => line !== "" || line.trim().length === 0); 

    // Removes first and last empty lines
    if (result[0] === "") {
        result.shift();
    }
    if (result[result.length - 1] === "") {
        result.pop();
    }

    return result;
}