import React, { useState, useContext } from 'react';
import {
    Box,
    TextField,
    Button,
    Paper,
    Alert,
    CircularProgress,
} from '@mui/material';
import { useTheme } from '../../context/ThemeContext';
import { generateInstructions } from '../../services/recap2viService';
import { UserContext } from '../../context/UserContext';
import Recap2InstructionsResult from "./Recap2InstructionsResult";
import Feedback from "../../components/common/Feedback"
import ToolHeader from "../../components/common/ToolHeader"
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar"

const Recap2Instructions = () => {
    const { isDarkMode } = useTheme();
    const [inputText, setInputText] = useState('');
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { user } = useContext(UserContext);
    const steps = ["Upload", "Generate Instructions"];
    const [currentStep, setCurrentStep] = useState(0);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    const handleGeneration = async () => {
        if (!inputText.trim()) {
            alert('Please enter fixture recap text');
            return;
        }

        console.log("Generating Voyage Instructions from Recap", {
            original_text: inputText,
            organisation_id: user.orgId,
        });

        setLoading(true);
        setError(null);

        try {
            const result = await generateInstructions(inputText, user.orgId);
            setResult(result);
            console.log(result);
        } catch (err) {
            setError('Error processing fixture recap: ' + err.message);
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <ToolHeader title={"Recap -> Voyage Instructions"} subtext={"Generate Voyage Instructions from a clean fixture recap"}></ToolHeader>
            <ToolStepProgressBar
                steps={steps}
                currentStep={currentStep}
                onStepChange={handleStepChange}
            />
            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ mb: 3 }}>
                <Paper
                    elevation={3}
                    sx={{
                        bgcolor: isDarkMode ? 'grey.800' : 'background.paper'
                    }}
                >
                    <TextField
                        fullWidth
                        multiline
                        rows={25}
                        variant="outlined"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        placeholder="Paste your recap text here..."
                    />
                </Paper>
            </Box>

            {/* Submit Button */}
            <Button
                onClick={handleGeneration}
                variant="contained"
                color="primary"
                sx={{ marginTop: "20px" }}
                disabled={!inputText.length === 0}
            >
                Generate Instructions
            </Button>

            {result && (
                <Feedback requestId={result.request_id} type={"Voyage Instructions"}></Feedback>
            )}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <Recap2InstructionsResult
                    response={result}
                />
            )}
        </Box>




    );
};

export default Recap2Instructions; 